import {useEffect,useState} from 'react'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { Footer } from './Footer'
import {toAbsoluteUrl } from '../../../../_metronic/helpers'
import io from 'socket.io-client'
const socket = io("https://app.digitalfactoryindia.com:3001");



const successSound = toAbsoluteUrl('/media/audio/new_orders.mp3');
const messageSound = toAbsoluteUrl('/media/audio/incoming_message.mp3');
const FooterWrapper = () => {
  const [toastAlert, setToastAlert] = useState(false);

  useEffect(() => {
    const Order = 0;

    //success_toast('1');
    // Assuming socket is properly initialized
    socket.emit('WS_NOTIF', Order);
    socket.on('WS_NOTIF_CALL', (Order: any) => {
      //console.log('Socket event received:', data);
      if (Order == 1) {
       // console.log('Before success_toast');
        success_toast();
      //  console.log('After success_toast');
      //  console.log('asdfsadfsadfsad');
      } else {
        console.log('Not Triggered');
      }

    });

    socket.emit('WS_NOTIF_MESSAGE', Order);
    socket.on('WS_KCO_NOTIF_MESSAGE_CALL', (Order: any) => {
      //console.log('Socket event received:', data);
      if (Order == 1) {
       // console.log('Before success_toast');
       message_toast();
      //  console.log('After success_toast');
      //  console.log('asdfsadfsadfsad');
      } else {
        console.log('Not Triggered');
      }

    });

  }, []);


const success_toast = () =>
{

  const successAudio = new Audio(successSound);
  successAudio.play()
    .catch(error => {
      console.error('Error playing audio:', error);
    });

  setToastAlert(true);
    // Automatically hide the toast after 2.5 seconds
    setTimeout(() => {
      setToastAlert(false);
    }, 12000); // 2500 milliseconds (2.5 seconds)
}

const message_toast = () =>
{

  const successAudio = new Audio(messageSound);
  successAudio.play()
    .catch(error => {
      console.error('Error playing audio:', error);
    });

}
  const { config } = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }


  return (
    <div className='app-footer' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <div className={clsx('app-container', config.app.footer.containerClass)}>
          <Footer />
        </div>
      ) : (
        <Footer />
      )}
    </div>
  )
}

export { FooterWrapper }