/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils' 
import { DashboardStats } from '../Interface'

type Props = {
  className: string
  stats: DashboardStats | undefined;
}

const Chart: React.FC<Props> = ({ className, stats }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [ad_Dates, setAd_Dates] = useState<string[]>([]);
  const [totalAdmission, setTotalAdmission] = useState<number[]>([]);  
   
  
  useEffect(() => {
    if (stats?.revenue_stats) {
      const dates = stats.revenue_stats.map((item:any) => item.day);
      const admissionData = stats.revenue_stats.map((item: any) => parseFloat(item.daily_admission));
      setAd_Dates(dates);
      setTotalAdmission(admissionData);
    }
  }, [stats]);

 useEffect(() => {
    if (chartRef.current && ad_Dates.length > 0) {
      const chart = new ApexCharts(chartRef.current, getChartOptions(totalAdmission,ad_Dates));
      chart.render();
      return () => {
        chart.destroy();
      };
    }
  }, [chartRef, ad_Dates, totalAdmission]);

  return (
    <div className={`card ${className}`}>
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Monthly Admission Stats</span>
        {totalAdmission.length > 0 ? (
          <span className='text-muted fw-semibold fs-7'> More than {totalAdmission.slice(-1)} new admission</span>
        ) : (
          <span className='text-muted fw-semibold fs-7'>{totalAdmission.length} orders  </span>
        )}
      </h3>
    </div>

    <div className='card-body'>
    {totalAdmission.length > 0 ? (
      <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
      ) : (
        <div className='text-muted fw-semibold fs-7 text-center pt-20' style={{ height: '350px' }}>No data available
        </div>
      )}
    </div>
  </div>
  )
}

export { Chart }

function getChartOptions(totalAdmission: number[], ad_Dates: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        name: 'Total Admissions',
        data: totalAdmission,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ad_Dates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ''
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}