import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const [userRole] = useState(currentUser?.user_role || '')

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />



      <SidebarMenuItem to="/leads" fontIcon='bi-layers' title="Leads" icon='people' />
      <SidebarMenuItem to="/contacts" fontIcon='bi-layers' title="Prospects" icon='address-book' />
      <SidebarMenuItemWithSub
        to='task'
        title='Task'
        icon='questionnaire-tablet'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='task/create-task' title='Create Task' hasBullet={true} />
        <SidebarMenuItem to='task/create-call' title='Create Call' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {userRole == "1" || userRole == "2" ? (
        <>
          <SidebarMenuItemWithSub
            to='crafted/settings/'
            title='Test Prep'
            icon='delivery-3'
            fontIcon='bi-layers'
          >
            <SidebarMenuItem to='courses/all-courses' title='List of courses' hasBullet={true} />
          </SidebarMenuItemWithSub>
        </>
      ) : null}


      <SidebarMenuItemWithSub
        to='crafted/settings/'
        title='Admissions'
        icon='teacher'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='admission/new-admission' title='New admission' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {userRole == "1" ? (
        <SidebarMenuItemWithSub
          to='user'
          title='Users'
          icon='user-edit'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='user/user-creation' title='Add New' hasBullet={true} />
        </SidebarMenuItemWithSub>
      ) : null}

      {userRole == "1" || userRole == "2" ? (
        <>
          <SidebarMenuItemWithSub
            to='report'
            title='Report'
            icon='graph-up'
            fontIcon='bi-layers'
          >
            <SidebarMenuItem to='report/lead-report' title='Leads Report' hasBullet={true} />
            <SidebarMenuItem to='report/task-report' title='Task Report' hasBullet={true} />
            <SidebarMenuItem to='report/admission-report' title='Admission Report' hasBullet={true} />
          </SidebarMenuItemWithSub>
        </>
      ) : null}

      <SidebarMenuItem to="/account/overview"
        fontIcon='bi-layers'
        title="My Account"
        icon='profile-circle' />

      {userRole == "1" ? (
        <>
          <hr />
          <div className="pt-2 pb-2 px-6">
            <span className="sidebar_menu_title">Marketing</span> <br></br>
            {/* <span className='text-white font-bold'>⚠️ Platform Access disabled due to non-payment of subscription fee. Kindly pay to restore access.</span> */}
           
          </div>
    

          <SidebarMenuItemWithSub
            to='whatsapp'
            title='Whatsapp Automation'
            icon='whatsapp'
            fontIcon='bi-layers'
          >
            <SidebarMenuItem to='whatsapp/conversation' title='Conversation' hasBullet={true} />
            <SidebarMenuItem to='whatsapp/broadcast' title='Broadcast' hasBullet={true} />
            <SidebarMenuItem to='whatsapp/catalogue' title='Catalogue' hasBullet={true} />
            <SidebarMenuItem to='whatsapp/template' title='Template' hasBullet={true} />
            <SidebarMenuItem to='whatsapp/integration' title='Integration' hasBullet={true} />
            <SidebarMenuItemWithSub to='/whatsapp/settings' title='Settings' hasBullet={true}>
              <SidebarMenuItem to='/whatsapp/settings/api' title='Api' hasBullet={true} />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>
        </>
      ) : null}
    </>
  )
}

export { SidebarMenuMain }
