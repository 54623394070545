import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Form, Formik, ErrorMessage, Field, FormikValues } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Customer_EDIT_Details, Chat_Tags_GET_data, Chat_Tags_POST_data, wp_Customer_UPDATE_data } from "../../../../auth/core/_requests"

const ChatDetails = ({ chatuser, userDetails, chatStart }: any) => {
    const [loading, setLoading] = useState(false)
    const [tagsAdd, setTagsAdd] = useState(false);
    const [newTags, setNewTags] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [tagsSelected, setTagsSelected] = useState<Array<{ value: string; label: string }>>([]);
    const [getTags, setGetTags] = useState<{ tag_id: string; tag_name: string, tag_created_on: string }[]>([]);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false)
    const [initialEditValues, setInitialEditValues] = useState(
        {
            contact_id_upd: '',
            customer_name_upd: '',
            customer_email_upd: '',
            customer_phone_upd: '',
            customer_address_upd: '',
        }
    )

    const UserDetails: any = localStorage.getItem('user-details')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.id;
    const user_token = userDetailsObject?.api_token
    const customerId = userDetailsObject?.cust_id
    const contactID = userDetails.length > 0 && userDetails[0].contact_id

    const handlEditModel = async (contact_id: any, contact_cust_id: any) => {
        setLoadingEdit(true)
        setShowEditModal(true);
        try {
            const response = await Customer_EDIT_Details(contact_id, contact_cust_id);
            setLoadingEdit(false)
            const initialData = response.data.contact_info
            setInitialEditValues(
                {
                    contact_id_upd: initialData.length > 0 ? initialData[0].contact_id : '',
                    customer_name_upd: initialData.length > 0 ? initialData[0].contact_first_name : '',
                    customer_phone_upd: initialData.length > 0 ? initialData[0].contact_mobile : '',
                    customer_email_upd: initialData.length > 0 ? initialData[0].contact_email : '',
                    customer_address_upd: initialData.length > 0 ? initialData[0].contact_address : '',
                }
            )
            const tagResponse = response.data.contact_info[0].contact_tags;
            const tagData = JSON.parse(tagResponse);
            const formattedArray = tagData.map((item: any) => ({ value: item, label: item }));
            setTagsSelected(formattedArray);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const validationSchemaEdit = Yup.object().shape({
        customer_name_upd: Yup.string().required('Customer Name is required'),
        customer_email_upd: Yup.string().email('Invalid email'),
    });

    const handleTags = (selected: any) => {
        setTagsSelected(selected);
        setTagsAdd(false);
    };

    const FetchTagsData = async (get_all_tags: any, tag_cust_id: any) => {
        try {
            const response = await Chat_Tags_GET_data(get_all_tags, tag_cust_id);
            setGetTags(response.data.tag_details);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        FetchTagsData("0", customerId);
    }, []);

    const handleTagsAdd = async (values: any) => {
        try {
            const newCategoryValue = values.tags;
            if (!newCategoryValue) {
                setTagsError('Category name cannot be empty');
                return; // Exit early if the tags is empty
            }

            // Check if the tags already exists in tagsSelected
            const isOptionAlreadySelected = tagsSelected.some(option =>
                option.value.toLowerCase() === newCategoryValue
            );

            if (!isOptionAlreadySelected) {
                // Make the API request to add the new tags
                const { data: auth } = await Chat_Tags_POST_data(
                    customerId,
                    newCategoryValue,
                    user_id,
                    user_token,
                );
                if (auth === 'Already Exist') {
                    // Handle the case where the API response indicates that the tags already exists
                    setTagsError('Category already exists. Please select from the dropdown.');
                } else {
                    // Update your component's state with the new tags
                    FetchTagsData("0", customerId);
                    setTagsSelected(prevCategorySelected => [
                        ...prevCategorySelected,
                        { value: newCategoryValue, label: values.tags }
                    ]);
                    setNewTags('');
                    // Clear the error message when a valid value is entered
                    setTagsError('');
                }
            } else {
                // Notify the user that the tags already exists
                setTagsError('Category already exists. Please select from the dropdown.');
            }
        } catch (error) {
            console.error('Error adding tags:', error);
        }
    };

    const handleCustomerUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const customerData = new FormData();
            const CategoryValues = tagsSelected.map(option => option.value);
            customerData.append('contact_tags_upd', JSON.stringify(CategoryValues));
            const response = await wp_Customer_UPDATE_data(
                customerData,
                values.contact_id_upd,
                values.customer_name_upd,
                values.customer_email_upd,
                values.customer_phone_upd,
                values.customer_address_upd,
                user_id,
                user_token,
                customerId
            )
            if (response.data.message === "success") {
                actions.resetForm();
                setShowEditModal(false);
                setLoading(false);
                setTagsSelected([]);
                chatStart(chatuser)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else if (response.data.message === "already_used") {
                setLoading(false);
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Mobile Number Already Exist',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>
            <div className='card' style={{ height: "82vh" }} id='kt_chat_messenger'>
                <div className='card-header' id='kt_chat_messenger_header'>
                    <div className='card-title'>
                        <div className='symbol symbol-30px symbol-circle me-3'>
                            <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/whatsapp.svg')} />
                        </div>
                        <div className='d-flex justify-content-center flex-column me-2'>
                            <span className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1' >
                                {chatuser}
                            </span>
                        </div>
                        <div onClick={() => handlEditModel(contactID, customerId)}>
                            <i className='far fa-edit text-primary cursor-pointer fs-4' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"></i>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <span className='text-gray-400 fw-bold fs-5'>Message Option In <KTIcon iconName='information-2' className='fs-2' /></span>
                    <h5 className='mt-5'>Yes</h5>
                    <div className='text-gray-400 fw-bold fs-5'>User Name</div>
                    <h5 className='mt-5'> <p>{userDetails.length > 0 && userDetails[0].contact_name}</p></h5>
                    <div className='text-gray-400 fw-bold fs-5'>Phone Number</div>
                    <h5 className='mt-5'> <p>{userDetails.length > 0 && userDetails[0].contact_mobile}</p></h5>
                    <span className='text-gray-400 fw-bold fs-5'>Email <KTIcon iconName='information-2' className='fs-2' /></span>
                    <h5 className='mt-5'>{userDetails.length > 0 && userDetails[0].contact_email}</h5>
                    <span className='text-gray-400 fw-bold fs-5'>Contact Owner <KTIcon iconName='information-2' className='fs-2' /></span>
                    <div className='mt-3 mb-3'>
                        <div className="symbol symbol-35px symbol-circle">
                            <span className={`symbol-label fs-2 fw-bold bg-danger text-white`}>
                                {userDetails.length > 0 && userDetails[0]?.contact_name ? userDetails[0]?.contact_name.charAt(0) : userDetails[0]?.contact_mobile.charAt(0)}
                            </span>
                        </div>
                    </div>
                    <span className='text-gray-400 fw-bold fs-5'>Contact Tags <KTIcon iconName='information-2' className='fs-2' /></span>
                    {/* <h5 className='mt-5'>{userDetails.length > 0 && JSON.parse(userDetails[0].contact_tags).join(', ')}</h5> */}
                    <h5 className='mt-5'>
                        {userDetails.length > 0 && JSON.parse(userDetails[0].contact_tags).map((tag: string) => (
                            <span key={tag} className={`badge bg-danger text-white ms-1 mt-3`}>{tag}</span>
                        ))}
                    </h5>
                </div>
            </div>

            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Details</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body" >
                                {loadingEdit ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Formik initialValues={initialEditValues} validationSchema={validationSchemaEdit} onSubmit={handleCustomerUpdate}>
                                            {() => (
                                                <Form>
                                                    <div className="form-group">
                                                        <input type="hidden" name="contact_id_upd" className="form-control" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="customer_name_upd" className='form-label mb-3 required'>Customer Name</label>
                                                        <Field type="text" name="customer_name_upd" className="form-control" />
                                                        <ErrorMessage name="customer_name_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label mt-3 mb-3 required" htmlFor="customer_phone_upd">Phone Number</label>
                                                        <Field type="number" name="customer_phone_upd" className="form-control form-control-lg" readOnly />
                                                        <ErrorMessage name="customer_phone_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label mt-3 mb-3" htmlFor="customer_email_upd">Email Address</label>
                                                        <Field type="text" name="customer_email_upd" className="form-control form-control-lg" />
                                                        <ErrorMessage name="customer_email_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="form-group">
                                                        <div className='mt-5'>
                                                            <label htmlFor="contact_tags" className='form-label mt-3 mb-2'>Tags</label>
                                                            <button type='button'
                                                                className={`Plus_icon_btn ${tagsAdd ? 'd-none' : ''}`}
                                                                onClick={() => setTagsAdd(true)} // Set tagsAdd to true when clicked
                                                            >
                                                                <i className="bi bi-plus plus_icon" />Add New
                                                            </button>
                                                            <button type='button'
                                                                className={`Plus_icon_btn ${tagsAdd ? '' : 'd-none'}`}
                                                                onClick={() => {
                                                                    setTagsError(''); // Clear the error message
                                                                    setTagsAdd(false); // Close the tags add section
                                                                }}
                                                            >
                                                                <i className="bi bi-x plus_icon" />Close
                                                            </button>
                                                        </div>
                                                        {tagsAdd && (
                                                            <div className='d-flex'>
                                                                <Field
                                                                    type="text"
                                                                    value={newTags}
                                                                    name='tags'
                                                                    onChange={(e: any) => {
                                                                        setNewTags(e.target.value);
                                                                        // Clear the error message when the user starts typing
                                                                        setTagsError('');
                                                                    }}
                                                                    className="form-control mt-5"
                                                                    placeholder='Enter New Tags'
                                                                />
                                                                <button
                                                                    type='button'
                                                                    onClick={() => handleTagsAdd({ tags: newTags })}
                                                                    className="btn btn-primary Add_new_cate_btn"
                                                                >
                                                                    Add
                                                                </button>
                                                            </div>
                                                        )}
                                                        {tagsError && <div className="text-danger">{tagsError}</div>}
                                                        <Select
                                                            isMulti
                                                            options={getTags && getTags.length > 0 ? getTags.map(item => ({
                                                                value: item.tag_name,
                                                                label: item.tag_name
                                                            })) : []}
                                                            value={tagsSelected}
                                                            name='contact_tags'
                                                            placeholder='Select Tags'
                                                            onChange={(tagsSelected) => {
                                                                handleTags(tagsSelected);
                                                            }}
                                                            className='w-100 mt-2'
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label mt-3 mb-3" htmlFor="customer_address_upd" >Customer Address</label>
                                                        <Field
                                                            name="customer_address_upd"
                                                            className="form-control"
                                                            as="textarea"
                                                        />
                                                        <ErrorMessage name="customer_address_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)} disabled={loading}>
                                                            Cancel
                                                        </button>
                                                        <div className='d-flex justify-content-end'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                )}
                            </div>
                        </div >
                    </div >
                </div >
            )}
        </>
    )
}

export default ChatDetails