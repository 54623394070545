import React, { useEffect, useState } from 'react'
import Pagination from '../../pages/Pagination'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import * as XLSX from 'xlsx';
import { AdmissionDetails } from '../../pages/Interface';
import { GET_ADMISSION_LIST } from '../../pages/CRM_GET';
import { useAuth } from '../auth';
import Loader from '../../pages/Loader';
import DataTable from 'react-data-table-component';

const AdmissionReport = () => {
    const { currentUser } = useAuth()
    const [userID] = useState(currentUser?.user_id || '')
    const [isLoading, setIsLoading] = useState(true);
    const [refreshAdmission, setRefreshAdmission] = useState(false);
    const [showAllTags, setShowAllTags] = useState(false);
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [admissionData, setAdmissionData] = useState<AdmissionDetails[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalAdmissionData, setTotalAdmissionData] = useState<AdmissionDetails[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterStatus, setFilterStatus] = useState('')
    const [filterSource, setFilterSource] = useState('')
    const [dateState, setDateState] = useState<any>({
        startDate: '',
        endDate: ''
    });

    const toggleExpand = (id: any) => {
        setExpandedItemId(prevId => (prevId === id ? null : id));
        setShowAllTags(!showAllTags)
    };

    useEffect(() => {
        GET_ADMISSION_LIST("0", userID, setAdmissionData, setIsLoading)
    }, [])

    const RefreshAdmissionData = () => {
        setRefreshAdmission(true);
        GET_ADMISSION_LIST("0", userID, setAdmissionData, setIsLoading)
            .then(response => {
                setRefreshAdmission(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    function jsonToExcel(jsonData: any[]) {
        // Define the custom headers
        const customHeaders = [
            {
                "S.No": "S.No",
                "Lead Counselor": "Lead Counselor",
                "Processing team": "Processing team",
                "Name": "Name",
                "Contact": "Contact",
                "Email": "Email",
                "Country": "Country",
                "Agent": "Agent",
                "Applied University": "Applied University",
                "Program": "Program",
                "Offer Letter": "Offer Letter",
                "Funding method": "Funding method",
                "Loan Process": "Loan Process",
                "Deposit Paid": "Deposit Paid",
                "Date of Payment": "Date of Payment",
                "CAS/I-20": "CAS/I-20",
                "Visa Status": "Visa Status",
                "Created by": "Created by",
            }
        ];

        // Prepend the headers to the data
        const dataWithHeaders = [...customHeaders, ...jsonData];

        const ws = XLSX.utils.json_to_sheet(dataWithHeaders, { skipHeader: true });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    }

    function downloadExcel(data: any[], filename: string) {
        const excelBuffer = jsonToExcel(data);
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }

    const handleDownloadReport = async () => {
        try {
            // Create a new array with the modified data
            const modifiedData = admissionData.map(admission => {
                const { admission_exam_status, admission_status, ...rest } = admission;
                // const statusType =
                //     admission.admission_status === '1' ? 'Joined' :
                //         admission.admission_status === '2' ? 'On Going' :
                //             admission.admission_status === '3' ? 'Cancelled' :
                //                 admission.admission_status === '4' ? 'Completed' :
                //                     '';
                // const examStatusType =
                //     admission.admission_exam_status === '1' ? 'Exam Cleared' :
                //         admission.admission_exam_status === '2' ? 'Not Cleared' :
                //             '';
                const appliedUniversitiesStr = admission.applied_universities.map((university: any) =>
                    `${university.applied_university_name || "-"} - ${university.applied_status || "-"}`
                ).join(', ');
                
                const offerLetterType =
                    rest.admission_offer_letter === '1' ? 'Conditional Offer' :
                        rest.admission_offer_letter === '2' ? 'Unconditional Offer' :
                            '';
                return {
                    "S.No": rest.admission_id,
                    "Lead Counselor": rest.admission_lead_from || "-",
                    "Processing team": rest.admission_processing_team || "-",
                    "Name": rest.admission_student_name || "-",
                    "Contact": rest.admission_student_mobile || "-",
                    "Email": rest.admission_student_email || "-",
                    "Country": rest.admission_country_interested === "Others" ? rest.admission_other_country :  rest.admission_country_interested || "-",
                    "Agent": rest.admission_agent || "-",
                    "Applied University": appliedUniversitiesStr || "-",
                    "Program": rest.admission_course_intereseted || "-",
                    "Offer Letter": offerLetterType || "-",
                    "Funding method": rest.admission_fund_method || "-",
                    "Loan Process": rest.admission_loan_process_type || "-",
                    "Deposit Paid": rest.admission_deposit_amount || "-",
                    "Date of Payment": rest.admission_deposit_date || "-",
                    "CAS/I-20": rest.admission_cas_status || "-",
                    "Visa Status": rest.admission_visa_status || "-",
                    "Created by": rest.admission_created_by,
                };
            });
            downloadExcel(modifiedData, 'Admission Report.xlsx');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const filtered = admissionData?.filter((app: any) => {
            const appData = `${app.admission_id} ${app.admission_student_name} ${app.admission_student_mobile} ${app.admission_student_email} ${app.admission_creator_name}`.toLowerCase();
            return appData.includes(searchQuery.toLowerCase());
        });
        setTotalAdmissionData(filtered);
    }, [searchQuery, admissionData]);

    useEffect(() => {
        const filteredOrders = admissionData.filter((item) => {
            const createdDate = new Date(item.admission_created_on.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1')); // Convert date string to Date object
            return (
                (!filterStatus || item.admission_status === filterStatus) &&
                (!filterSource || item.admission_exam_status === filterSource) &&
                (!dateState.startDate || createdDate >= dateState.startDate) &&
                (!dateState.endDate || createdDate <= dateState.endDate)
            );
        });
        setTotalAdmissionData(filteredOrders);
    }, [filterStatus, filterSource, admissionData, dateState]);

    const handleFilterExport = async () => {
        try {
            const modifiedFilteredData = admissionData.map(admission => {
                const { admission_exam_status, admission_status, ...rest } = admission;
                const appliedUniversitiesStr = admission.applied_universities.map((university: any) =>
                    `${university.applied_university_name || "-"} - ${university.applied_status || "-"}`
                ).join(', ');
                
                const offerLetterType =
                    rest.admission_offer_letter === '1' ? 'Conditional Offer' :
                        rest.admission_offer_letter === '2' ? 'Unconditional Offer' :
                            '';
                return {
                    "S.No": rest.admission_id,
                    "Lead Counselor": rest.admission_lead_from || "-",
                    "Processing team": rest.admission_processing_team || "-",
                    "Name": rest.admission_student_name || "-",
                    "Contact": rest.admission_student_mobile || "-",
                    "Email": rest.admission_student_email || "-",
                    "Country": rest.admission_country_interested === "Others" ? rest.admission_other_country :  rest.admission_country_interested || "-",
                    "Agent": rest.admission_agent || "-",
                    "Applied University": appliedUniversitiesStr || "-",
                    "Program": rest.admission_course_intereseted || "-",
                    "Offer Letter": offerLetterType || "-",
                    "Funding method": rest.admission_fund_method || "-",
                    "Loan Process": rest.admission_loan_process_type || "-",
                    "Deposit Paid": rest.admission_deposit_amount || "-",
                    "Date of Payment": rest.admission_deposit_date || "-",
                    "CAS/I-20": rest.admission_cas_status || "-",
                    "Visa Status": rest.admission_visa_status || "-",
                    "Created by": rest.admission_created_by,
                };
            });
            downloadExcel(modifiedFilteredData, 'Filtered Admission Report.xlsx');
        } catch (error) {
            console.error('Error exporting filtered data:', error);
        }
    };

    const handleStatusFilter = (e: any) => {
        setFilterStatus(e.target.value);
    };

    const handleExamTypeFilter = (e: any) => {
        setFilterSource(e.target.value);
    };

    const itemsPerPage = 10;
    const totalItems = admissionData.length;
    const filterDataTotal = totalAdmissionData.length
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const AdmissionData = totalAdmissionData.slice(startIndex, startIndex + itemsPerPage);

    const clearDate = () => {
        setDateState({ date: null });
    };

    const columns: any = [
        {
            name: 'S.No',
            selector: (row: any) => row.admission_id,
            sortable: true,
            minWidth: '80px',
            cell: (row: any) => (row.admission_id),
            style: {
                position: 'sticky',
                left: 0,
                zIndex: 1,
                backgroundColor: 'inherit',
            },
        },
        {
            name: 'Lead Counselor',
            selector: (row: any) => row.admission_lead_from || "-",
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'Processing team',
            selector: (row: any) => row.admission_processing_team || "-",
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'Name',
            selector: (row: any) => row.admission_student_name || "-",
            sortable: true,
            cell: (row: any) => (
                <div>
                    {row.admission_student_name || "-"}
                </div>
            ),
            style: {
                position: 'sticky',
                left: 70,
                zIndex: 1,
                backgroundColor: 'inherit',
            },
        },
        {
            name: 'Contact',
            selector: (row: any) => row.admission_student_mobile || "-",
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row: any) => row.admission_student_email || "-",
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'Country',
            selector: (row: any) => row.admission_country_interested === "Others" ? row.admission_other_country : row.admission_country_interested || "-",
            sortable: true,
            minWidth: '150px',
        },
        {
            name: 'Agent',
            selector: (row: any) => row.admission_agent || "-",
            sortable: true,
        },
        {
            name: 'Applied University',
            sortable: true,
            minWidth: '250px',
            cell: (row: any) => (
                <div>
                    {row.applied_universities.length > 0 ? (
                        <div className="position-relative">
                            <strong>{row.applied_universities[0].applied_university_name || "-"}</strong> - {row.applied_universities[0].applied_status || "-"}

                            {/* Dropdown for full details */}
                            <div className="dropdown position-absolute start-0 mt-n2">
                                <button className="btn btn-sm text-primary btn-link text-decoration-none" type="button" id={`dropdownMenu-${row.admission_id}`} data-bs-toggle="dropdown" aria-expanded="false">
                                    view more
                                </button>
                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenu-${row.admission_id}`}>
                                    {row.applied_universities.map((university: any, index: number) => (
                                        <li key={index}>
                                            <button className="dropdown-item" type="button">
                                                <strong>{university.applied_university_name || "-"}</strong> - {university.applied_status || "-"}
                                                <br />
                                                <small>Applied on: {university.applied_on || "-"}</small>
                                                <br />
                                                <small>Applied by: {university.applied_by || "-"}</small>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        "-"
                    )}
                </div>
            ),
        },
        {
            name: 'Program',
            selector: (row: any) => row.admission_course_intereseted || "-",
            sortable: true,
            minWidth: '250px',
        },
        {
            name: 'Offer Letter',
            selector: (row: any) => row.admission_offer_letter == "1" ? "Conditional Offer" : row.admission_offer_letter == "2" ? "Unconditional Offer" : '',
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'Funding method',
            selector: (row: any) => row.admission_fund_method || "-",
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'Loan Process',
            selector: (row: any) => row.admission_loan_process_type || "-",
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'Deposit Paid',
            selector: (row: any) => row.admission_deposit_amount || "-",
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'Date of Payment',
            selector: (row: any) => row.admission_deposit_date || "-",
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'CAS/I-20',
            selector: (row: any) => row.admission_cas_status || "-",
            sortable: true,
        },
        {
            name: 'Visa Status',
            selector: (row: any) => row.admission_visa_status || "-",
            sortable: true,
            minWidth: '250px',
        },
        {
            name: 'Created by',
            selector: (row: any) => row.admission_created_by,
            minWidth: '250px',
            sortable: true,
            cell: (row: any) => (
                <div>
                    {row.admission_created_by}
                    <span className="fw-semibold d-block fs-7">{row.admission_created_on}</span>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='card bg_none'>
                <div className='card-header p-0 border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Admission</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total {admissionData.length} Admission</span>
                    </h3>
                    <div className='card-toolbar'>
                        <button className="btn btn-primary refresh_icon me-5" onClick={RefreshAdmissionData}>{!refreshAdmission ? (
                            <i className="bi bi-arrow-clockwise fs-2"></i>
                        ) : (
                            <i className="spinner-border spinner-border-sm fs-2"></i>
                        )} </button>
                        <button className="btn btn-primary refresh_icon me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <i className="las la-filter fs-2" />
                        </button>
                        <div>
                            <button className='btn btn-primary' onClick={handleDownloadReport}>
                                <KTIcon iconName='file-down' className='fs-2' />
                                Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div className="collapse" id="collapseExample">
                <div className='card bg_none'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                        </h3>
                        <div className='card-toolbar'>
                            <div className='d-flex me-2'>
                                <Flatpickr
                                    value={dateState.date}
                                    onChange={([startDate, endDate]) => {
                                        setDateState({ startDate, endDate });
                                    }}
                                    options={{
                                        mode: "range",
                                    }}
                                    className='form-control form-control-solid'
                                    placeholder='Pick date'
                                />
                                {dateState.startDate && dateState.endDate && (
                                    <button className="btn btn-sm btn-secondary" onClick={clearDate}>
                                        <KTIcon iconName='abstract-11' className='' />
                                    </button>
                                )}
                            </div>
                            <div className='me-2'>
                                <select className='form-select' onChange={handleExamTypeFilter}
                                    value={filterSource}>
                                    <option value="">Exam Status</option>
                                    <option value="1">Exam Cleared</option>
                                    <option value="2">Not Cleared</option>
                                </select>
                            </div>
                            <div className='me-2'>
                                <select
                                    className='form-select'
                                    onChange={handleStatusFilter}
                                    value={filterStatus}
                                >
                                    <option value="">Status</option>
                                    <option value="1">Joined</option>
                                    <option value="2">On Going </option>
                                    <option value="3">Cancelled</option>
                                    <option value="4">Completed</option>
                                </select>
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleFilterExport}
                                disabled={!filterStatus && !filterSource && !dateState.startDate && !dateState.endDate}
                            >
                                <KTIcon iconName='file-down' className='fs-2 me-2' />
                                Export Filtered
                            </button>
                            <div className="d-flex align-items-center">
                                <div className="input-group">
                                    <input type="search" placeholder='Search Name...'
                                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                        className="form-control w-50" />
                                    <button type="button" className="btn btn-primary">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (AdmissionData.length > 0 ? (
                <div className={`table-responsive mt-5 ${refreshAdmission ? 'd opacity-50' : ''}`} >
                    <DataTable
                        columns={columns}
                        data={AdmissionData}
                        highlightOnHover
                        pointerOnHover
                        responsive
                        customStyles={{
                            headCells: {
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    backgroundColor: '#fff',
                                },
                            },
                            cells: {
                                style: {
                                    padding: '25px',
                                },
                            },
                        }}
                    />
                    {filterStatus || filterSource || searchQuery ? (
                        <Pagination currentPage={currentPage} totalPages={filterTotalPage} handlePageChange={handlePageChange} />
                    ) : (
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                    )}
                </div>
            ) :
                <div className="d-flex justify-content-center p-10">
                    <div>
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">No Admission Found</h3>
                    </div>
                </div>
            )}
        </>
    )
}

export default AdmissionReport