import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'
import LeadsReport from './LeadsReport'
import TaskReport from './TaskReport'
import AdmissionReport from './AdmissionReport'

 
const ReportPage: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='lead-report'
                    element={
                        <>
                            <LeadsReport />
                        </>
                    }
                />
                <Route
                    path='task-report'
                    element={
                        <>
                            <TaskReport />
                        </>
                    }
                />
                <Route
                    path='admission-report'
                    element={
                        <>
                            <AdmissionReport />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default ReportPage
