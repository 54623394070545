import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import TaskForm from './TaskForm'
import { ContactDetails, SelectOption, LeadDatas, TaskDetails, UserDetails } from '../../../pages/Interface'
import { CONTACTS_GET_data, GET_ALL_LEADS, TASK_DELETE_DATA, TASK_GET_DATA, TASK_EDIT_DATA, GET_ALL_USERS } from '../../auth/core/_requests'
import PaginationNew from '../../../pages/PaginationNew';
import Loader from '../../../pages/Loader';
import Swal from 'sweetalert2';
import TaskEdit from './TaskEdit';
import { useAuth } from '../../auth';


const Task = () => {
  const { currentUser } = useAuth()
  const [userRole] = useState(currentUser?.user_role || '')
  const { task_status } = useParams<{ task_status: string }>();
  const [refreshTask, setRefreshTask] = useState(false);
  const [taskModal, setTaskModal] = useState(false)
  const [leadsOwnerSelected, setLeadsOwnerSelected] = useState<{ value: string; label: string }>();
  const [taskAssignby, setTaskAssignby] = useState<SelectOption[] | null>(null);
  const [customers, setCustomers] = useState<ContactDetails[]>([]);
  const [leadsData, setLeadsData] = useState<LeadDatas[]>([]);
  const [taskData, setTaskData] = useState<TaskDetails[]>([]);
  const [userData, setUserData] = useState<UserDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPerPage, setShowPerPage] = useState(10);
  const [taskTotalDatas, setTaskTotalDatas] = useState<TaskDetails[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
  const [filterStatus, setFilterStatus] = useState(task_status)
  const [filterSource, setFilterSource] = useState('')
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAllTags, setShowAllTags] = useState(false);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [initialEditValues, setInitialEditValues] = useState(
    {
      task_id_upd: '',
      task_type_upd: '',
      task_user_number_upd: '',
      task_user_email_upd: '',
      task_due_date_upd: '',
      task_due_time_upd: '',
      task_status_upd: '',
      task_priority_upd: '',
      task_subject_upd: '',
      task_description_upd: '',
      task_reminder_time_upd: '',
      task_reminder_notify_upd: '',
    }
  )

  const toggleExpand = (id: any) => {
    setExpandedItemId(prevId => (prevId === id ? null : id));
    setShowAllTags(!showAllTags)
  };

  const handleTaskModal = () => {
    setTaskModal(true)
    setLeadsOwnerSelected(null as any)
    setTaskAssignby(null as any)
  }

  const UserDetails: any = localStorage.getItem('kc-auth')
  const userDetailsObject = JSON.parse(UserDetails);
  const user_id = userDetailsObject?.user_id

  const fetchContactsList = async (all_contacts: any) => {
    try {
      const response = await CONTACTS_GET_data(all_contacts);
      setCustomers(response.data.contact_details);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchContactsList("0");
  }, []);

  const fetchLeadsData = async (all_leads: any) => {
    try {
      const response = await GET_ALL_LEADS(all_leads);
      setLeadsData(response.data.leads_details);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  useEffect(() => {
    fetchLeadsData("0",);
  }, []);

  const fetchUserData = async (all_users: any) => {
    try {
      const response = await GET_ALL_USERS(all_users);
      setUserData(response.data.user_details);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  useEffect(() => {
    fetchUserData("0");
  }, []);

  const fetchTaskList = async (get_all_task: any, task_user_id: any) => {
    try {
      const response = await TASK_GET_DATA(get_all_task, task_user_id);
      setTaskData(response.data.task_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching task:', error);
    }
  };

  useEffect(() => {
    fetchTaskList("0", user_id);
  }, []);

  const RefreshTaskData = () => {
    setRefreshTask(true);
    fetchTaskList("0", user_id)
      .then(response => {
        setRefreshTask(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  const showTaskNotification = (taskName: any, dueTime: any) => {
    Swal.fire({
      title: "Task Reminder!",
      text: `You have a scheduled task to ${taskName} at ${dueTime}`,
      icon: "warning",
      width: 600,
      padding: "3em",
      color: "#716add",
      background: "#fff url(/images/trees.png)",
      backdrop: `
        rgba(0,0,123,0.4)
        url("/images/nyan-cat.gif")
        left top
        no-repeat
      `,
      showCancelButton: true,
      confirmButtonText: 'OK',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Dismiss',
      allowOutsideClick: false,
    });
  };

  const contactOptions = customers.map((item: any) => ({
    value: item.contact_id,
    label: `${item.contact_first_name} ${item.contact_last_name} (${item.contact_tag_sep})`,
  }));

  const leadsOptions = leadsData.map((item: any) => ({
    value: item.leads_id,
    label: `${item.leads_first_name} ${item.leads_last_name} (${item.leads_tags_sep})`,
  }));



  const CustomOption = (props: any) => {
    const { innerRef, innerProps, data } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ padding: '10px' }}>
        <div>{data.displayLabel}</div>
        <div style={{ fontSize: '12px', color: '#ff6a56' }}>
          {data.subLabel ? "Tags :" : ""} {data.subLabel}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (userRole === "3") {
      // Parse the task reminder date and time for each task
      taskData.map((item) => {
        // Convert task reminder date and time to proper format
        const reminderTaskTime = new Date(item.task_reminder_on);
        const taskTime = item.task_due_time;

        // Calculate the milliseconds until the reminder
        const timeUntilReminder = reminderTaskTime.getTime() - Date.now();

        // If the reminder time has not already passed, schedule the popup
        if (timeUntilReminder > 0) {
          // Schedule the popup notification using setTimeout
          const timerId = setTimeout(() => {
            showTaskNotification(item.task_related_to_name, taskTime);
          }, timeUntilReminder);

          // Clean up the timer to avoid memory leaks
          return () => clearTimeout(timerId);
        }
      });
    }
  }, [userRole, taskData]);

  const handleEditTask = async (task_id: any, task_user_id: any) => {
    setLoadingEdit(true)
    setShowEditModal(true)
    try {
      const response = await TASK_EDIT_DATA(task_id, task_user_id);
      setLoadingEdit(false)
      const initialData = response.data.task_details
      const leadsOwnerResponse = response.data.task_details[0].task_assignee_name;
      const leadOwnerValues = leadsOwnerResponse
      let selectedOption: any = null;
      selectedOption = userData.find((item: any) => item.user_name === leadOwnerValues);
      if (selectedOption) {
        setLeadsOwnerSelected({
          value: selectedOption.user_id,
          label: selectedOption.user_name
        });
      }
      const TaskReletedSelect = response.data.task_details[0].task_related_to;
      setSelectedOption(TaskReletedSelect)
      const TaskReletedTo = response.data.task_details[0].task_related_to_name.trim().toLowerCase();
      let taskRelatedContact: ContactDetails | undefined; // Define the type of taskRelatedContact
      let taskRelatedLead: LeadDatas | undefined; // Define the type of taskRelatedLead

      if (Array.isArray(customers) && customers.length > 0) {
        taskRelatedContact = customers.find((item: ContactDetails) => {
          const contactFirstName = `${item.contact_first_name} ${item.contact_last_name}`.trim().toLowerCase();
          return contactFirstName === TaskReletedTo;
        });
      }

      if (Array.isArray(leadsData) && leadsData.length > 0) {
        taskRelatedLead = leadsData.find((item: LeadDatas) => {
          const leadsName = `${item.leads_first_name} ${item.leads_last_name}`.trim().toLowerCase();
          return leadsName === TaskReletedTo;
        });
      }

      if (TaskReletedSelect === "Contact") {
        if (taskRelatedContact) {
          setTaskAssignby([{
            value: taskRelatedContact.contact_id,
            label: `${taskRelatedContact.contact_first_name} ${taskRelatedContact.contact_last_name}`
          }]);
        }
      } else {
        if (taskRelatedLead) {
          setTaskAssignby([{
            value: taskRelatedLead.leads_id,
            label: `${taskRelatedLead.leads_first_name} ${taskRelatedLead.leads_last_name}`
          }]);
        }
      }
      const TaskReminderTime = response.data.task_details[0].task_reminder_on;
      const reminderTimeOnly = TaskReminderTime.substring(11, 16); // Extracting characters from index 11 to 16 (exclusive)

      setInitialEditValues({
        task_id_upd: initialData.length > 0 ? initialData[0].task_id : '',
        task_type_upd: initialData.length > 0 ? initialData[0].task_type : '',
        task_user_number_upd: initialData.length > 0 ? initialData[0].task_user_number : '',
        task_user_email_upd: initialData.length > 0 ? initialData[0].task_user_email : '',
        task_due_date_upd: initialData.length > 0 ? initialData[0].task_due_date : '',
        task_due_time_upd: initialData.length > 0 ? initialData[0].task_due_time : '',
        task_status_upd: initialData.length > 0 ? initialData[0].task_status : '',
        task_priority_upd: initialData.length > 0 ? initialData[0].task_priority : '',
        task_subject_upd: initialData.length > 0 ? initialData[0].task_subject : '',
        task_description_upd: initialData.length > 0 ? initialData[0].task_description : '',
        task_reminder_time_upd: reminderTimeOnly,
        task_reminder_notify_upd: initialData.length > 0 ? initialData[0].task_reminder_mode : '',
      })
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleTaskDelete = (task_delete_id: string, task_deleted_by: string) => {
    const handleDeleteEvent = async () => {
      try {
        const response = await TASK_DELETE_DATA(task_delete_id, task_deleted_by);
        const filterdata = taskData.filter(
          (item) => item.task_id !== task_delete_id
        );
        setTaskData(filterdata);
      } catch (error) {
        console.error("Error deleting tasks:", error);
      }
    };
    handleDeleteEvent();
  };

  const handleDeleteConfirmation = (task_delete_id: string, task_deleted_by: string) => {
    Swal.fire({
      title: 'Are you sure!',
      text: "Delete this task..?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
        cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Call your delete function here
        handleTaskDelete(task_delete_id, task_deleted_by);
        Swal.fire(
          'Deleted!',
          'Your task has been deleted.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Canceling will not save any changes :)',
          'error'
        )
      }
    });
  };

  useEffect(() => {
    const filtered = taskData?.filter((app: any) => {
      const appData = `${app.task_id} ${app.task_assignee_name} ${app.task_related_to_name} ${app.task_type} ${app.task_created_by}`.toLowerCase();
      return appData.includes(searchQuery.toLowerCase());
    });
    setTaskTotalDatas(filtered);
  }, [searchQuery, taskData]);

  const sortByColumn = (column: string) => {
    const sortedData = [...taskData];

    if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
      sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
      setSortOrder('desc');
    } else {
      sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
      setSortOrder('asc');
    }
    setSortColumn(column);
    setTaskData(sortedData);
  };

  useEffect(() => {
    const filteredOrders = taskData.filter((item) => {
      if (!filterStatus && !filterSource) {
        return true;
      }
      return (
        (!filterStatus || item.task_status == filterStatus) &&
        (!filterSource || item.task_type == filterSource)
      );
    });
    setTaskTotalDatas(filteredOrders);
  }, [filterStatus, filterSource, taskData]);

  const handleLeadStatusFilter = (e: any) => {
    setFilterStatus(e.target.value);
  };

  const handleLeadSourceFilter = (e: any) => {
    setFilterSource(e.target.value);
  };

  const handleItemsPerPageChange = (perPage: number) => {
    setShowPerPage(perPage);
    setCurrentPage(1);
  };

  const itemsPerPage = 10;
  const totalItems = taskData.length;
  const filterDataTotal = taskTotalDatas.length
  const totalPages = Math.ceil(totalItems / showPerPage);
  const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
  const startIndex = (currentPage - 1) * showPerPage;
  const endIndex = startIndex + showPerPage;
  const taskDetails = taskTotalDatas.slice(startIndex, endIndex);

  return (
    <>
      <div className='card bg_none'>
        <div className='card-header p-0 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Task</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {taskData.length} Task</span>
          </h3>
          {userRole != "3" && (
            <>
              <div className='card-toolbar'>
                <button className="btn btn-primary refresh_icon me-5" onClick={RefreshTaskData}>{!refreshTask ? (
                  <i className="bi bi-arrow-clockwise fs-2"></i>
                ) : (
                  <i className="spinner-border spinner-border-sm fs-2"></i>
                )} </button>
                <button className="btn btn-primary refresh_icon me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                  <i className="las la-filter fs-2" />
                </button>
                <div>
                  <button className='btn btn-primary refresh_icon' onClick={handleTaskModal}>
                    <KTIcon iconName='plus-circle' className='fs-2' />
                    Create Task
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <hr />
      <div className="collapse" id="collapseExample">
        <div className='card bg_none'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
            </h3>
            <div className='card-toolbar'>
              <div className='me-5'>
                <select className='form-select' onChange={handleLeadSourceFilter}
                  value={filterSource}>
                  <option value="">Task Type</option>
                  <option value="1">Counseling</option>
                  <option value="2">Email</option>
                  <option value="3">Meeting</option>
                </select>
              </div>
              <div className='me-5'>
                <select
                  className='form-select'
                  onChange={handleLeadStatusFilter}
                  value={filterStatus}
                >
                  <option value="">Status</option>
                  <option value="1">Not started</option>
                  <option value="6">Pending</option>
                  <option value="3">In progress</option>
                  <option value="4">Waiting For Input</option>
                  <option value="5">Completed</option>
                </select>
              </div>
              <div className="d-flex align-items-center position-relative">
                <div className="input-group">
                  <input type="search" placeholder='Search Name...'
                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control w-50" />
                  <button type="button" className="btn btn-primary">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (taskDetails.length > 0 ? (
        <div className={`mt-5 ${refreshTask ? 'd opacity-50' : ''}`} >
          <table className='table gs-0 gy-4'>
            <thead className='table_bg_color'>
              <tr className='fw-bold fs-6'>
                <th className={`ps-5 ${sortColumn === 'task_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_id')}>
                  S.No
                </th>
                <th className={`${sortColumn === 'task_assignee_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_assignee_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_assignee_name')}>
                  Task Owner
                </th>
                <th className={`${sortColumn === 'task_related_to_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_related_to_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_related_to_name')}>Task Related To</th>
                <th className={`${sortColumn === 'task_user_number' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_user_number' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_user_number')}>
                  Phone
                </th>
                <th className={`${sortColumn === 'task_user_email' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_user_email' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_user_email')}>
                  Email
                </th>
                <th className={`${sortColumn === 'task_tags' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_tags' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_tags')}>
                  Tags
                </th>
                <th className={`${sortColumn === 'task_type' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_type' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_type')}>
                  Task Type
                </th>
                <th className={`${sortColumn === 'task_due_date' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_due_date' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_due_date')}>
                  Due Date
                </th>
                <th className={`${sortColumn === 'task_status' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_status' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_status')}>
                  Status
                </th>
                <th className={`${sortColumn === 'task_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_created_on')}>
                  Created by
                </th>
                <th className='pe-5'>Actions</th>
              </tr>
            </thead>
            <tbody className='bg-light'>
              {taskDetails.map((task, index) => (
                <tr key={index} className='border-bottom  border-gray-300'>
                  <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>{task.task_id}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6 d-flex border-0'>
                    <div className="symbol symbol-circle symbol-35px">
                      <img src={task.task_assignee_pic ? task.task_assignee_pic : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                    </div>
                    <div>
                      {task.task_assignee_name}
                    </div>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{task.task_related_to_name ? task.task_related_to_name : "-"}
                    <span className="fw-semibold d-block fs-7">Source : {task.task_related_to ? task.task_related_to : "-"}</span>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {task.task_user_number ? task.task_user_number : "-"}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {task.task_user_email ? task.task_user_email : "-"}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {typeof task.task_tags === 'string' ? (() => {
                      const tags = task.task_tags.replace(/[\[\]"]+/g, '').split(',').map(tag => tag.trim());
                      return (
                        <>
                          {tags.length > 0 && (
                            <span className="badge badge-success me-2 p-1">{tags[0]}</span>
                          )}
                          <div className="dropdown d-inline">
                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                              {tags.length > 1 && (
                                <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(task.task_id)}>
                                  {expandedItemId === task.task_id ? "Hide" : "+"}
                                  {expandedItemId === task.task_id ? "" : tags.length - 1}
                                </span>
                              )}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                              {showAllTags && tags.slice(1).map(tag => (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    <span key={tag}>{tag}</span>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      );
                    })() : '-'}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {task.task_type == "1" ? "Counseling" :
                      task.task_type == "2" ? "Email" :
                        task.task_type == "3" ? "Meeting" : ""
                    }
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-7'>
                    {task.task_due_date ? task.task_due_date : "-"}
                    <span className="fw-semibold d-block fs-7">{task.task_due_time ? task.task_due_time : "-"}</span>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {task.task_status === '1' ? (
                      <span className='badge badge-primary text-white'>Not started</span>
                    ) : task.task_status === '6' ? (
                      <span className='badge badge-danger text-white'>Pending</span>
                    ) : task.task_status === '3' ? (
                      <span className='badge badge-warning text-white'>In progress</span>
                    ) : task.task_status === '4' ? (
                      <span className='badge bg-dark text-white'>Waiting For Input</span>
                    ) : (
                      <span className='badge badge-success text-white'>Completed</span>
                    )}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{task.task_created_by}
                    <span className="fw-semibold d-block fs-7">{task.task_created_on}</span>
                  </td>
                  <td style={{ paddingTop: "10px" }}>
                    <div className="dropdown">
                      <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-three-dots fs-5"></i>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleEditTask(task.task_id, user_id)}
                          >
                            Edit
                          </button>
                        </li>
                        {userRole != "3" && (
                          <>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleDeleteConfirmation(task.task_id, user_id)}
                              >
                                Delete
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filterStatus || filterSource || searchQuery ? (
            <PaginationNew
              currentPage={currentPage}
              totalPages={filterTotalPage}
              itemsPerPage={itemsPerPage}
              handlePageChange={setCurrentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          ) : (
            <PaginationNew
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={showPerPage}
              handlePageChange={setCurrentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      ) :
        <div style={{ minHeight: "55vh" }} className='d-flex align-items-center justify-content-center'>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className='text-center'>
                  <img src={toAbsoluteUrl('/media/stock/task.png')} className='w-250px' />
                  <h4>No Task Found</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`modal${taskModal ? ' show' : ''}`} role="dialog" style={{ display: taskModal ? 'block' : 'none' }}>
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <TaskForm setTaskModal={setTaskModal} leadsOwnerSelected={leadsOwnerSelected}
            setLeadsOwnerSelected={setLeadsOwnerSelected} setTaskAssignby={setTaskAssignby}
            taskAssignby={taskAssignby} fetchTaskList={fetchTaskList}
            contactOptions={contactOptions} leadsOptions={leadsOptions}
          />
        </div>
      </div>

      {showEditModal && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <TaskEdit loadingEdit={loadingEdit} setShowEditModal={setShowEditModal}
              initialEditValues={initialEditValues} leadsOwnerSelected={leadsOwnerSelected} setLeadsOwnerSelected={setLeadsOwnerSelected}
              fetchTaskList={fetchTaskList} setTaskAssignby={setTaskAssignby}
              taskAssignby={taskAssignby} selectedOption={selectedOption} setSelectedOption={setSelectedOption}
              contactOptions={contactOptions} CustomOption={CustomOption} leadsOptions={leadsOptions}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Task