import React, { useState, useEffect } from 'react';
import { CONTACTS_GET_data, CONTACTS_EDIT_DATA, CONTACTS_ADD_ADMISSION, CONTACTS_MULTIPLE_ADMISSION_ADD, CONTACTS_DELETE_DATA, CONTACTS_MULTIPLE_DELETE_DATA, Tags_GET_data, Tags_POST_data, GET_ALL_USERS } from '../../modules/auth/core/_requests';
import { toAbsoluteUrl, KTIcon } from '../../../_metronic/helpers'
import Swal from 'sweetalert2';
import PaginationNew from '../PaginationNew';
import Loader from '../Loader';
import CustomerForm from './ContactForm';
import ContactEditForm from './ContactEditForm';
import ContactImport from './ContactImport';
import toast, { Toaster } from "react-hot-toast";
import { ContactDetails, UserDetails } from '../Interface';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
    const { currentUser } = useAuth()
    const navigate = useNavigate()
    const [userRole] = useState(currentUser?.user_role || '')
    const [openModal, setOpenModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [imageUpload, setImageUpload] = useState<File[]>([]);
    const [customers, setCustomers] = useState<ContactDetails[]>([]);
    const [userData, setUserData] = useState<UserDetails[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAllTags, setShowAllTags] = useState(false);
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPerPage, setShowPerPage] = useState(10);
    const [filteredMembers, setFilteredMembers] = useState<ContactDetails[]>([]);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [leadsOwnerSelected, setLeadsOwnerSelected] = useState<{ value: string; label: string }>();
    const [tagsAdd, setTagsAdd] = useState(false);
    const [newTags, setNewTags] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [getTags, setGetTags] = useState<{ tag_id: string; tag_name: string, tag_created_on: string }[]>([]);
    const [tagsSelected, setTagsSelected] = useState<Array<{ value: string; label: string }>>([]);
    const [showEditModal, setShowEditModal] = useState(false)
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
    const [selectContactId, setSelectContactId] = useState<string[]>([]);
    const [initialEditValues, setInitialEditValues] = useState(
        {
            contact_id_upd: '',
            contact_lead_source_upd: '',
            contact_title_upd: '',
            contact_first_name_upd: '',
            contact_last_name_upd: '',
            contact_phone_number_upd: '',
            contact_email_upd: '',
            contact_dob_upd: '',
            contact_department_upd: '',
            contact_assistant_upd: '',
            contact_assistant_phone_upd: '',
            contact_current_qualification_upd: '',
            contact_country_interested_upd: '',
            contact_course_interested_upd: '',
            contact_status_upd: '',
            contact_address_line_1_upd: '',
            contact_address_line_2_upd: '',
            contact_address_street_upd: '',
            contact_address_city_upd: '',
            contact_address_state_upd: '',
            contact_address_zipcode_upd: '',
            contact_address_country_upd: '',
            contact_description_upd: '',
        }
    )

    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const Login_user_id = userDetailsObject?.user_id

    const handleAddCustomer = () => {
        setOpenModal(true);
        setImageUpload([])
        setTagsSelected([]);
        setLeadsOwnerSelected(null as any)
    };

    const handleImportCustomer = () => {
        setImportModal(true);
    };

    const toggleExpand = (id: any) => {
        setExpandedItemId(prevId => (prevId === id ? null : id));
        setShowAllTags(!showAllTags)
    };

    const handleTags = (selected: any) => {
        setTagsSelected(selected);
    };

    const fetchUserData = async (all_users: any) => {
        try {
            const response = await GET_ALL_USERS(all_users);
            setUserData(response.data.user_details);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    useEffect(() => {
        fetchUserData("0");
    }, []);

    const handleAddTags = async (values: any) => {
        try {
            const newTagsValue = values.tags;
            if (!newTagsValue) {
                setTagsError('Tags name cannot be empty');
                return; // Exit early if the tags is empty
            }
            const isOptionAlreadySelected = tagsSelected.some(option =>
                option.value.toLowerCase() === newTagsValue
            );

            if (!isOptionAlreadySelected) {
                const { data: auth } = await Tags_POST_data(
                    newTagsValue,
                    Login_user_id,
                );

                if (auth === 'Already Exist') {
                    setTagsError('Tags already exists. Please select from the dropdown.');
                } else {
                    FetchTagsData("0");
                    setTagsSelected(prevTagsSelected => [
                        ...prevTagsSelected,
                        { value: newTagsValue, label: values.tags }
                    ]);
                    setNewTags('');
                    setTagsError('');
                }
            } else {
                setTagsError('Tags already exists. Please select from the dropdown.');
            }
        } catch (error) {
            console.error('Error adding tags:', error);
        }
    };

    const handleEditCustomers = async (contact_id: any) => {
        setLoadingEdit(true)
        setShowEditModal(true)
        try {
            const response = await CONTACTS_EDIT_DATA(contact_id);
            setLoadingEdit(false)
            const initialData = response.data.contact_info
            setImageUpload([response.data.contact_info[0].contact_user_pic]);
            const tagResponse = response.data.contact_info[0].contact_tags;
            const tagData = JSON.parse(tagResponse);
            const formattedArray = tagData.map((item: any) => ({ value: item, label: item }));
            setTagsSelected(formattedArray);
            const leadsOwnerResponse = response.data.contact_info[0].contact_owner;
            const leadOwnerValues = leadsOwnerResponse
            // Add null check for leadsOwnerSelected
            let selectedOption: any = null;
            selectedOption = userData.find((item: any) => item.user_id === leadOwnerValues);
            if (selectedOption) {
                setLeadsOwnerSelected({
                    value: selectedOption.user_id,
                    label: selectedOption.user_name
                });
            }
            setInitialEditValues({
                contact_id_upd: initialData.length > 0 ? initialData[0].contact_id : '',
                contact_lead_source_upd: initialData.length > 0 ? initialData[0].contact_lead_source : '',
                contact_title_upd: initialData.length > 0 ? initialData[0].contact_title : '',
                contact_first_name_upd: initialData.length > 0 ? initialData[0].contact_first_name : '',
                contact_last_name_upd: initialData.length > 0 ? initialData[0].contact_last_name : '',
                contact_phone_number_upd: initialData.length > 0 ? initialData[0].contact_mobile : '',
                contact_email_upd: initialData.length > 0 ? initialData[0].contact_email : '',
                contact_dob_upd: initialData.length > 0 ? initialData[0].contact_dob : '',
                contact_department_upd: initialData.length > 0 ? initialData[0].contact_department : '',
                contact_assistant_upd: initialData.length > 0 ? initialData[0].contact_assistant : '',
                contact_assistant_phone_upd: initialData.length > 0 ? initialData[0].contact_assistant_phone : '',
                contact_current_qualification_upd: initialData.length > 0 ? initialData[0].contact_current_qualification : '',
                contact_country_interested_upd: initialData.length > 0 ? initialData[0].contact_country_interested : '',
                contact_course_interested_upd: initialData.length > 0 ? initialData[0].contact_course_interested : '',
                contact_status_upd: initialData.length > 0 ? initialData[0].contact_status : '',
                contact_address_line_1_upd: initialData.length > 0 ? initialData[0].contact_address_line1 : '',
                contact_address_line_2_upd: initialData.length > 0 ? initialData[0].contact_address_line2 : '',
                contact_address_street_upd: initialData.length > 0 ? initialData[0].contact_address_street : '',
                contact_address_city_upd: initialData.length > 0 ? initialData[0].contact_address_city : '',
                contact_address_state_upd: initialData.length > 0 ? initialData[0].contact_address_state : '',
                contact_address_zipcode_upd: initialData.length > 0 ? initialData[0].contact_address_zipcode : '',
                contact_address_country_upd: initialData.length > 0 ? initialData[0].contact_address_country : '',
                contact_description_upd: initialData.length > 0 ? initialData[0].contact_description : '',
            })
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const handleAddAdmission = async (contact_admission_id: any, contact_admission_add_by: any) => {
        try {
            const response = await CONTACTS_ADD_ADMISSION(contact_admission_id, contact_admission_add_by);
            if (response.data.message === 'success') {
                toast.success("Admission Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 1500
                });
                setTimeout(() => {
                    navigate('/admission/new-admission');
                }, 1500);
            } else if (response.data.message === 'already_exist') {
                toast.error("This Prospects is already added", {
                    style: {
                        background: '#FFFF00',
                        color: 'black',
                    }, duration: 5000
                });
            } else {
                toast.error("Failed to add admission", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
            }
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const handleAddMultipleAdmission = async (contact_admission_id: any, contact_admission_add_by: any) => {
        try {
            const response = await CONTACTS_MULTIPLE_ADMISSION_ADD(contact_admission_id, contact_admission_add_by);
            if (response.data.message === 'success') {
                toast.success("Admission Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 1500
                });
                setTimeout(() => {
                    navigate('/admission/new-admission');
                }, 1500);
            } else if (response.data.message === 'already_exist') {
                toast.error("This Prospects is already added", {
                    style: {
                        background: '#FFFF00',
                        color: 'black',
                    }, duration: 5000
                });
            } else {
                toast.error("Failed to add admission", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
            }
        } catch (error) {
            console.error('Error fetching contact:', error);
        }
    };

    const FetchTagsData = async (all_tags: any) => {
        try {
            const response = await Tags_GET_data(all_tags);
            setGetTags(response.data.tag_details);
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    useEffect(() => {
        FetchTagsData("0");
    }, []);

    const ContactDelete = (contact_del_id: string) => {
        const handleDeleteEvent = async () => {
            try {
                const response = await CONTACTS_DELETE_DATA(contact_del_id);
                const filterdata = customers.filter(
                    (item) => item.contact_id !== contact_del_id
                );
                setCustomers(filterdata);
            } catch (error) {
                console.error("Error deleting contact:", error);
            }
        };
        handleDeleteEvent();
    };

    const handleDeleteConfirmation = (contact_del_id: string) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this contact..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                ContactDelete(contact_del_id);
                Swal.fire(
                    'Deleted!',
                    'Your contact has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handleSelectCheckboxSelect = (id: string) => {
        setSelectContactId((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
        );
    };

    const handleSelectAllCheckboxes = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allContactIds = CustomerDetails.map((member) => member.contact_id);
            setSelectContactId(allContactIds);
        } else {
            setSelectContactId([]);
        }
    };

    const MultipleContactDelete = (contact_deleted_id: string, contact_deleted_by: string) => {
        const handleDeleteMultiple = async () => {
            try {
                const response = await CONTACTS_MULTIPLE_DELETE_DATA(contact_deleted_id, contact_deleted_by);
                const filterdata = customers.filter(
                    (item) => item.contact_id !== contact_deleted_id
                );
                setCustomers(filterdata);
            } catch (error) {
                console.error("Error deleting contact:", error);
            }
        };
        handleDeleteMultiple();
    };

    const handleMultipleDeleteConfirmation: any = (contact_deleted_id: string) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this contact..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                MultipleContactDelete(contact_deleted_id, Login_user_id);
                Swal.fire(
                    'Deleted!',
                    'Your contact has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const fetchContactsList = async (all_contacts: any) => {
        try {
            const response = await CONTACTS_GET_data(all_contacts);
            setCustomers(response.data.contact_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchContactsList("0");
    }, []);

    useEffect(() => {
        const filtered = customers?.filter((app: any) => {
            const appData = `${app.contact_id} ${app.contact_first_name} ${app.contact_last_name} ${app.contact_mobile} ${app.contact_email} ${app.contact_owner}`.toLowerCase();
            return appData.includes(searchQuery.toLowerCase());
        });
        setFilteredMembers(filtered);
    }, [searchQuery, customers]);

    const sortByColumn = (column: string) => {
        const sortedData = [...customers];

        if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
            sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
            setSortOrder('desc');
        } else {
            sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
            setSortOrder('asc');
        }
        setSortColumn(column);
        setCustomers(sortedData);
    };

    const handleItemsPerPageChange = (perPage: number) => {
        setShowPerPage(perPage);
        setCurrentPage(1);
    };

    const itemsPerPage = 10;
    const totalItems = customers.length;
    const filterDataTotal = filteredMembers.length
    const totalPages = Math.ceil(totalItems / showPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
    const startIndex = (currentPage - 1) * showPerPage;
    const endIndex = startIndex + showPerPage;
    const CustomerDetails = filteredMembers.slice(startIndex, endIndex);
    return (
        <>
            <div className='card bg_none'>
                <div className='card-header p-0 border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Prospects</span>
                        <span className='mt-1 fw-bold fs-7'>Total {customers.length} Prospects</span>
                    </h3>
                    <div className='card-toolbar'>
                        {
                            selectContactId.length > 0 ? (
                                <div className='d-flex align-items-center'>
                                    <span className='fw-bolder'>{selectContactId.length} Contact{selectContactId.length > 1 ? "'s" : ""} Selected
                                    </span>
                                    < div className='ms-5'>
                                        <button className='btn btn-sm btn-danger' onClick={() => handleMultipleDeleteConfirmation(selectContactId)}>
                                            <KTIcon iconName='trash' className='fs-2' />
                                            Delete Selected
                                        </button>
                                    </div>
                                    < div className='ms-5'>
                                        <button className='btn btn-sm btn-primary' onClick={() => handleAddMultipleAdmission(selectContactId, Login_user_id)}>
                                            <KTIcon iconName='teacher' className='fs-2' />
                                            Add Admission
                                        </button>
                                    </div>
                                </div>
                            ) :
                                <>
                                    <div className="d-flex align-items-center me-5">
                                        <div className="input-group">
                                            <input type="search" placeholder='Search Name & Phone...' className="form-control w-50"
                                                value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            <button type="button" className="btn btn-primary">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='me-5'>
                                        <button className='btn btn-info' onClick={handleImportCustomer}>
                                            <KTIcon iconName='file-down' className='fs-2' />
                                            Import
                                        </button>
                                    </div>
                                    <div>
                                        <button className='btn btn-primary' onClick={handleAddCustomer}>
                                            <KTIcon iconName='plus-circle' className='fs-2' />
                                            Add Contact
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div >

            <hr />

            <Toaster />
            {
                isLoading ? (
                    <>
                        <Loader />
                    </>
                ) : (CustomerDetails.length > 0 ? (
                    <div className='mt-5 table-responsive'>
                        <table className='table align-middle gs-0 gy-4'>
                            <thead className='table_bg_color'>
                                <tr className='fw-bold fs-6'>
                                    <th>
                                        <div className="form-check form-check-custom form-check-solid ps-5 ">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={handleSelectAllCheckboxes}
                                            />
                                        </div>
                                    </th>
                                    <th className={`${sortColumn === 'contact_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_id')}>
                                        S.No
                                    </th>
                                    <th className={`${sortColumn === 'contact_owner' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_owner' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_owner')}>
                                        Owner
                                    </th>
                                    <th className={`${sortColumn === 'contact_first_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_first_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_first_name')}>
                                        Name
                                    </th>
                                    <th className={`${sortColumn === 'contact_mobile' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_mobile' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_mobile')}>
                                        Number
                                    </th>
                                    <th className={`${sortColumn === 'contact_address_line1' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_address_line1' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_address_line1')}>
                                        Location
                                    </th>
                                    <th>
                                        Qualification
                                    </th>
                                    <th>
                                        Course
                                    </th>
                                    <th className={`${sortColumn === 'contact_tags' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_tags' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_tags')}>
                                        Tags
                                    </th>
                                    <th className={`${sortColumn === 'contact_tags' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_tags' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_tags')}>
                                        Status
                                    </th>
                                    <th className={`min-w-100px ${sortColumn === 'contact_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_created_on')}>
                                        Created On
                                    </th>
                                    <th>Remarks</th>
                                    <th className='pe-5'>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='bg-light'>
                                {CustomerDetails.map((contact, index) => (
                                    <tr key={index} className='border-bottom  border-gray-300'>
                                        <td>
                                            <div className="form-check form-check-custom form-check-solid ps-5 ">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selectContactId.includes(contact.contact_id)}
                                                    onChange={() => handleSelectCheckboxSelect(contact.contact_id)}
                                                />
                                            </div>
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>{contact.contact_id}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_owner ? contact.contact_owner : "-"}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6 border-bottom-0'>
                                            {/* <div className="symbol symbol-circle symbol-35px">
                                            <img src={contact.contact_user_pic ? contact.contact_user_pic : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                                        </div>
                                        <div> */}
                                            {contact.contact_first_name}  {contact.contact_last_name}
                                            {/* </div> */}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_mobile ? contact.contact_mobile : "-"}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_address_city ? contact.contact_address_city : "-"}</td>

                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_current_qualification ? contact.contact_current_qualification : "-"}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {contact.contact_course_interested ? contact.contact_course_interested : "-"} <br />
                                            <small> {contact.contact_country_interested ? contact.contact_country_interested : "-"} </small>
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {Array.isArray(contact.contact_tags) ? (
                                                <>
                                                    {contact.contact_tags.length > 0 && (
                                                        <span className="badge badge-success me-2 p-1">{contact.contact_tags[0]}</span>
                                                    )}
                                                    <div className="dropdown d-inline">
                                                        <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {contact.contact_tags.length > 1 && (
                                                                <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(contact.contact_id)}>
                                                                    {expandedItemId === contact.contact_id ? "Hide" : "+"}
                                                                    {expandedItemId === contact.contact_id ? "" : contact.contact_tags.length - 1}
                                                                </span>
                                                            )}
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            {showAllTags &&
                                                                contact.contact_tags.slice(1).map(tag => (
                                                                    <li>
                                                                        <button
                                                                            className="dropdown-item"
                                                                            type="button"

                                                                        >
                                                                            <span key={tag}>{tag}</span>
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {contact.contact_status === '1' ? (
                                                <span className='badge bg_badge_color3 p-2 text-white'>Interested</span>
                                            ) : contact.contact_status === '2' ? (
                                                <span className='badge bg-danger p-2 text-white'>Not interested</span>
                                            ) : contact.contact_status === '3' ? (
                                                <span className='badge bg-info p-2 text-white'>Beyond Intake</span>
                                            ) : contact.contact_status === '4' ? (
                                                <span className='badge bg_badge_color2 p-2 text-white'>Lost to competitor</span>
                                            ) : contact.contact_status === '5' ? (
                                                <span className='badge bg_badge_color1 text-white'>Financially not sound</span>
                                            ) : contact.contact_status === '6' ? (
                                                <span className='badge badge-primary text-white'>Loan Rejected</span>
                                            ) : (
                                                    <>-</>
                                                )}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_created_by}
                                            <span className="fw-semibold d-block fs-7">{contact.contact_created_on}</span>
                                        </td>
                                        <td className="text-dark fw-bold text-hover-primary fs-6">
                                            {
                                                contact.contact_last_call_attend_by ?
                                                    <>
                                                        <small>Last Call:</small>
                                                        <small> {contact.contact_last_call_attend_by}</small>
                                                        <span className="fw-semibold d-block fs-7">{contact.contact_last_call_on}</span>
                                                    </>
                                                    : '-'
                                            }
                                        </td>
                                        <td style={{ paddingTop: "10px" }}>
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots fs-5"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <li>
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={() => handleEditCustomers(contact.contact_id)}
                                                        >
                                                            Edit
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={() => handleAddAdmission(contact.contact_id, Login_user_id)}
                                                        >
                                                            Add to Admission
                                                        </button>
                                                    </li>
                                                    {userRole != "3" && (
                                                        <>
                                                            <li>
                                                                <button
                                                                    className="dropdown-item"
                                                                    type="button"
                                                                    onClick={() => handleDeleteConfirmation(contact.contact_id)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {searchQuery ? (
                            <PaginationNew
                                currentPage={currentPage}
                                totalPages={filterTotalPage}
                                itemsPerPage={itemsPerPage}
                                handlePageChange={setCurrentPage}
                                handleItemsPerPageChange={handleItemsPerPageChange}
                            />
                        ) : (
                            <PaginationNew
                                currentPage={currentPage}
                                totalPages={totalPages}
                                itemsPerPage={showPerPage}
                                handlePageChange={setCurrentPage}
                                handleItemsPerPageChange={handleItemsPerPageChange}
                            />
                        )}
                    </div >
                ) :
                    <div className="d-flex justify-content-center p-10">
                        <div>
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                            <h3 className="text-center">No contacts found</h3>
                        </div>
                    </div>
                )
            }

            <div className={`modal${openModal ? ' show' : ''}`} role="dialog" style={{ display: openModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <CustomerForm setOpenModal={setOpenModal} tagsAdd={tagsAdd} getTags={getTags} setTagsAdd={setTagsAdd} newTags={newTags} setNewTags={setNewTags}
                            tagsSelected={tagsSelected} setTagsSelected={setTagsSelected} handleAddTags={handleAddTags} handleTags={handleTags} tagsError={tagsError}
                            leadsOwnerSelected={leadsOwnerSelected} setLeadsOwnerSelected={setLeadsOwnerSelected} fetchContactsList={fetchContactsList} />
                    </div>
                </div>
            </div>

            <div className={`modal${importModal ? ' show' : ''}`} role="dialog" style={{ display: importModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <ContactImport setImportModal={setImportModal} Login_user_id={Login_user_id} fetchContactsList={fetchContactsList} />
                </div>
            </div>

            {
                showEditModal && (
                    <div className="modal" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                            <ContactEditForm loadingEdit={loadingEdit} setShowEditModal={setShowEditModal} tagsAdd={tagsAdd} getTags={getTags}
                                setTagsAdd={setTagsAdd} newTags={newTags} setNewTags={setNewTags} tagsSelected={tagsSelected} handleAddTags={handleAddTags}
                                handleTags={handleTags} tagsError={tagsError} setTagsError={setTagsError} imageUpload={imageUpload} setImageUpload={setImageUpload} initialEditValues={initialEditValues}
                                leadsOwnerSelected={leadsOwnerSelected} setLeadsOwnerSelected={setLeadsOwnerSelected} fetchContactsList={fetchContactsList}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Contact