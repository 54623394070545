import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'


const HeaderNotificationsMenu = ({stats}:any) => { 
    return (
        <>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
                data-kt-menu='true'
            >
                <div
                    className='d-flex flex-column bgi-no-repeat rounded-top'
                    style={{ backgroundImage: `url('${toAbsoluteUrl('/media/avatars/menu-header-bg.jpg')}')` }}
                >
                    <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
                        Notifications 
                    </h3>
 
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
                        <li className="nav-item">
                            <a
                                className="nav-link active text-white opacity-75 opacity-state-100 pb-4"
                                data-bs-toggle="tab"
                                href="#task_logs"
                            >
                                Task Logs
                            </a>
                        </li>
                    </ul>
                </div>
 
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="task_logs"
                        role="tabpanel"
                    >
                        <div className='scroll-y mh-325px my-5 px-8'>
                            {stats?.log_details?.logs.length === 0 ? (
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-300px' />
                                        <h3 className="text-center">No log found</h3>
                                    </div>
                                </div>
                            ) : (
                                stats?.log_details?.logs.map((log:any, index:any) => (
                                    <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                                        <div className='d-flex align-items-center'> 
                                            <div className='mb-0 me-2'>
                                                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                                                    {log.log_message}
                                                </a>
                                                <div className='text-dark-400 fs-7'>Type - {log.log_type}</div>
                                                <div className='text-dark-400 fs-7'>Created By - {log.log_created_by}</div>
                                            </div>
                                        </div>

                                        <span className='badge badge-light fs-8'>{log.log_created_on} {log.log_created_time}</span>
                                    </div>
                                )))}
                        </div>

                        {/* <div className='py-3 text-center border-top'>
                            <Link
                                to='/crafted/account/overview'
                                className='btn btn-color-gray-600 btn-active-color-primary'
                            >
                                View All <KTIcon iconName='arrow-right' className='fs-5' />
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export { HeaderNotificationsMenu }
