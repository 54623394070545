import React, { useState } from 'react'
import { Form, Formik, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast';
import Select from 'react-select';
import Loader from '../../../pages/Loader';
import { LOG_CALL_PUT_DATA } from '../../auth/core/_requests';
import { SelectOption } from '../../../pages/Interface';
import { MultiSelect } from 'react-multi-select-component';

const LogCallEdit = ({ loadingEdit, setShowEditModal, initialEditValues, fetchCallList,
    setTaskAssignby, taskAssignby, selectedOption, setSelectedOption, contactOptions, leadsOptions }: any) => {
    const [loading, setLoading] = useState(false)


    const validationSchemaEdit = Yup.object().shape({
        call_task_type_upd: Yup.string().required('Task type is required'),
    });

    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const userId = userDetailsObject?.user_id ? userDetailsObject?.user_id : "";



    const handleSelectChange = (event: React.MouseEvent<HTMLButtonElement>, value: string) => {
        event.preventDefault(); // Prevent the default form submission
        setSelectedOption(value);
        setTaskAssignby(null as any);
    };

    const handleRelatedChange = (selected: any) => {
        // Only allow one selection at a time
        if (selected.length > 1) {
            selected = [selected[selected.length - 1]];
        }
        setTaskAssignby(selected);
    };
    const transformedTaskAssignby: SelectOption[] = taskAssignby ? taskAssignby.map((item: any) => ({ value: item.value, label: item.label })) : [];

    const handleLogCallUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const CallToId = taskAssignby[0].value
            const response = await LOG_CALL_PUT_DATA(
                values.call_task_id_upd,
                selectedOption,
                CallToId,
                values.call_task_type_upd,
                values.call_status_upd,
                values.call_start_date_upd,
                values.call_start_time_upd,
                values.call_end_time_upd,
                values.call_duration_upd,
                values.call_subject_upd,
                values.call_purpose_upd,
                values.call_agenda_upd,
                values.call_result_upd,
                values.call_description_upd,
                values.call_reminder_upd,
                userId
            )
            if (response.data.message === 'success') {
                toast.success("Call Log Updated successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setTaskAssignby(null as any)
                actions.resetForm();
                fetchCallList("0", userId)
                setShowEditModal(false);
            } else {
                toast.error("Failed to update log", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Call Information Details:</h5>
                    <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                {loadingEdit ? (
                    <Loader />
                ) : (
                    <Formik validationSchema={validationSchemaEdit} initialValues={initialEditValues} onSubmit={handleLogCallUpdate}>
                        {({ values }) => (
                            <Form>
                                <div className="modal-body form_content">
                                    <div className="form-group">
                                        <input type="hidden" name="call_task_id_upd" className="form-control" />
                                    </div>

                                    <h4 className="mb-5">Call Information</h4>

                                    <div className="form-group mb-3">
                                        <label htmlFor="task_owner" className='form-label mb-3 required'>Call To</label>
                                        <div className="input-group mb-3">
                                            <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{selectedOption}</button>
                                            <ul className="dropdown-menu">
                                                <li><button className="dropdown-item" onClick={(e) => handleSelectChange(e, 'Lead')}>Lead</button></li>
                                                <li><button className="dropdown-item" onClick={(e) => handleSelectChange(e, 'Contact')}>Contact</button></li>
                                            </ul>

                                            <MultiSelect
                                                options={selectedOption === 'Lead' ? leadsOptions : contactOptions}
                                                value={transformedTaskAssignby}
                                                onChange={handleRelatedChange}
                                                className={selectedOption === 'Lead' ? 'task_leads_dropdown' : 'task_contacts_dropdown'}
                                                labelledBy="Select"
                                                hasSelectAll={false}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label mb-3" htmlFor="task_user_number_upd">Phone Number</label>
                                        <Field type="number" name="task_user_number_upd" className="form-control form-control-sm text-dark" title="Read Only" disabled /> 
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label mb-3" htmlFor="task_user_email_upd">Email</label>
                                        <Field type="text" name="task_user_email_upd" className="form-control form-control-sm text-dark" title="Read Only" disabled/> 
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="call_task_type_upd" className="form-label mb-3 required">Call Type</label>
                                        <Field as="select" name="call_task_type_upd" aria-label="Select example" className="form-select form-select-sm">
                                            <option value="1">Outbound</option>
                                            <option value="2">Inbound</option>
                                            <option value="3">Missed</option>
                                        </Field>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="call_status_upd" className="form-label mb-3 required">Outgoing Call Status</label>
                                        <Field as="select" name="call_status_upd" aria-label="Select example" className="form-select form-select-sm">
                                            <option value="1">Completed</option>
                                            <option value="2">Scheduled</option>
                                            <option value="3">Not Connected</option>
                                            <option value="4">Unable to Pick Call</option>
                                        </Field>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="call_start_date_upd" className="form-label mb-3 required">Call Start Date</label>
                                        <Field name="call_start_date_upd" type="date" className="form-control form-control-sm" />
                                    </div>
                                    <div className="form-group  mb-3">
                                        <label className="form-label mb-3 required">Call Start Time & End Time</label>
                                        <div className='input-group'>
                                            <Field name="call_start_time_upd" type="time" className="form-control form-control-sm" />
                                            <Field name="call_end_time_upd" type="time" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    {values.call_task_type_upd != "3" && (
                                        <div className="form-group mb-3">
                                            <label htmlFor="call_duration_upd" className="form-label mb-3 required">Call Duration</label>
                                            <Field name="call_duration_upd" type="text" className="form-control form-control-sm" />
                                        </div>
                                    )}
                                    {values.call_status_upd == "2" && (
                                        <div className="form-group mb-3">
                                            <label htmlFor="call_reminder_upd" className="form-label mb-3">Reminder</label>
                                            <Field as="select" name="call_reminder_upd" aria-label="Select example" className="form-select form-select-sm">
                                                <option value="">-None-</option>
                                                <option value="5 mins before">5 mins before</option>
                                                <option value="`10 mins before">10 mins before</option>
                                                <option value="`15 mins before">15 mins before</option>
                                                <option value="`30 mins before">30 mins before</option>
                                                <option value="`1 hour before">1 hour before</option>
                                            </Field>
                                        </div>
                                    )}
                                    <div className="form-group mb-3">
                                        <label htmlFor="call_subject_upd" className="form-label mb-3 required">Subject</label>
                                        <Field name="call_subject_upd" type="text" className="form-control form-control-sm" />
                                    </div>


                                    {values.call_task_type_upd === "2" ? (
                                        <>
                                            <h4 className="mt-5 mb-5">Reason For Incoming Call</h4>
                                            <div className="form-group mb-3">
                                                <label htmlFor="call_description_upd" className="form-label mb-3 required">Description</label>
                                                <Field name="call_description_upd" type="text" className="form-control form-control-sm" />
                                            </div>
                                        </>
                                    ) : values.call_task_type_upd === "1" ? (
                                        <>
                                            <h4 className="mt-5 mb-5">Purpose Of Outgoing Call</h4>

                                            <div className="form-group mb-3">
                                                <label htmlFor="call_purpose_upd" className="form-label mb-3 required">Call Purpose</label>
                                                <Field as="select" name="call_purpose_upd" aria-label="Select example" className="form-select form-select-sm">
                                                    <option value="">-None-</option>
                                                    <option value="1">Prospective</option>
                                                    <option value="2">Negotiation</option>
                                                </Field>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="call_agenda_upd" className="form-label mb-3 required">Call Agenda</label>
                                                <Field name="call_agenda_upd" type="text" className="form-control form-control-sm" />
                                            </div>

                                            <h4 className="mt-5 mb-5">Outcome Of Outgoing Call</h4>
                                            <div className="form-group mb-3">
                                                <label htmlFor="call_result_upd" className="form-label mb-3 required">Call Result</label>
                                                <Field as="select" name="call_result_upd" aria-label="Select example" className="form-select form-select-sm">
                                                    <option value="1">Interested</option>
                                                    <option value="2">Not Interested</option>
                                                    <option value="3">No Response/Busy</option>
                                                    <option value="4">Requested More Info</option>
                                                    <option value="5">Requested call back</option>
                                                    <option value="6">Invalid Number</option>
                                                </Field>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="call_description_upd" className="form-label mb-3 required">Description</label>
                                                <Field name="call_description_upd" type="text" className="form-control form-control-sm" />
                                            </div>
                                        </>
                                    ) : ''}
                                </div>
                                <div className="modal-footer">
                                    <div className='d-flex justify-content-end'>
                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                            {!loading && 'Submit'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    )
}

export default LogCallEdit