import React, { useState } from 'react'
import { Form, Formik, FormikValues, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import { whatsapp_broadcast_filters } from '../../../auth/core/_requests';


interface FiltersDatas {
    datas: string;
}

const SegmentFilterForm = ({ setShowModal,setIsApplied,setCustomers }: any) => {
    const [searchData, setSearchData] = useState<FiltersDatas[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);

    const initialFilterValues = {
        broadcast_source_type: '',
        broadcast_select_type: '',
        broadcast_select_format: '',
        broadcast_search_type: '',
    }

    const FiltervalidationSchema = Yup.object().shape({
        broadcast_source_type: Yup.string().required('Please Select Source'),
        broadcast_select_type: Yup.string().required('Please Select anyone'),
        broadcast_select_format: Yup.string().required('Please Select anyone'),
        broadcast_search_type: Yup.string().required('Search Customers'),
    });

    const handleSearchData = async (values: any) => {
        const broadcastmode = 0;
        try {
            setSearchQuery(values.broadcast_search_type);
            const response = await whatsapp_broadcast_filters(
                values.broadcast_source_type,
                values.broadcast_select_type,
                values.broadcast_select_format,
                values.broadcast_search_type,
                broadcastmode
            );
            const data = await response.data;
            setSearchData(data.filter_data);
            //console.log(searchData, 'searchData')
        } catch (error) {
            console.error('GET Request Error:', error);
        }
    };



    const handleSearchItemClick = (selectedValue: any) => {
        setSearchQuery(selectedValue);
        setSearchData([]);
    };

    const handleApply = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const broadcastmode = 1;
            const response = await whatsapp_broadcast_filters(
                values.broadcast_source_type,
                values.broadcast_select_type,
                values.broadcast_select_format,
                searchQuery,
                broadcastmode
            );
            setLoading(false)
            setIsApplied(true);
            const data = await response.data;
            setCustomers(data.filter_data);
            setShowModal(false);
            actions.resetForm();
            setSearchQuery('')
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Filter Options
                       </h5>
                    <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                </div>
                <Formik validationSchema={FiltervalidationSchema} initialValues={initialFilterValues} onSubmit={handleApply}>
                    {({ values, setFieldValue }) => (
                        <Form id="bulk-import">
                            <div className="modal-body">
                                <div className="form-group">
                                    <Field
                                        as="select"
                                        name="broadcast_source_type"
                                        aria-label="Select example"
                                        className='form-select form-select-solid'
                                    >
                                        <option value=''>Select Source...</option>
                                        <option value='Leads'>Leads</option>
                                        <option value='Prospects'>Prospects</option> 
                                    </Field>
                                    <ErrorMessage name="broadcast_source_type" component="div" className="text-danger" />
                                </div>

                                <div className="form-group  mt-5">
                                    <Field
                                        as="select"
                                        name="broadcast_select_type"
                                        aria-label="Select example"
                                        className='form-select form-select-solid'
                                    >
                                        <option value=''>Select...</option>
                                        <option value='Name'>Name</option>
                                        <option value='Phone Number'>Phone Number</option>
                                        <option value='Email'>Email</option>
                                        <option value='Tags'>Tags</option>
                                        <option value='Created At'>Created At</option>
                                        <option value='Updated At'>Updated At</option>
                                        <option value='Creator'>Creator</option>
                                    </Field>
                                    <ErrorMessage name="broadcast_select_type" component="div" className="text-danger" />
                                </div>

                                <div className="form-group mt-5">
                                    <Field
                                        as="select"
                                        name="broadcast_select_format"
                                        aria-label="Select example"
                                        className='form-select form-select-solid'
                                    >
                                        <option value=''>Select...</option>
                                        <option value='='>Contain</option>
                                        <option value='!='>Does not Contain</option>
                                        <option value='IS NULL'>Is Empty</option>
                                        <option value='IS NOT NULL'>Is not Empty</option>
                                    </Field>
                                    <ErrorMessage name="broadcast_select_format" component="div" className="text-danger" />
                                </div>

                                <div className="form-group mt-5 mb-5">
                                    <div className="d-flex align-items-center position-relative">
                                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                            <span className="path1"></span><span className="path2"></span>
                                        </i>
                                        <Field
                                            type="text"
                                            name="broadcast_search_type"
                                            value={searchQuery}
                                            onChange={(e: any) => {
                                                setSearchQuery(e.target.value);
                                                handleSearchData({ ...values, broadcast_search_type: e.target.value });
                                                setFieldValue('broadcast_search_type', e.target.value);
                                            }}
                                            className="form-control form-control-solid ps-12"
                                            placeholder="Search Customers by Name"
                                        />
                                    </div>
                                    <ErrorMessage name="broadcast_search_type" component="div" className="text-danger" />
                                    {searchData.length > 0 && (
                                        <div className='search_filter'>
                                            {searchData.map((item) => (
                                                <ul key={item.datas} onClick={() => handleSearchItemClick(item.datas)}>
                                                    <li className='search_filter_list'>
                                                        {item.datas}
                                                    </li>
                                                </ul>
                                            ))}
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className='d-flex justify-content-end'>
                                    <button type="button" className="btn btn-secondary me-3" disabled={loading} onClick={() => setShowModal(false)}>
                                        Cancel
                                    </button>
                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='submit'
                                            className='btn btn-primary'
                                            disabled={loading}
                                        >
                                            {!loading && 'Apply'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default SegmentFilterForm