import React, { useEffect, useState } from 'react';
import { GET_ALL_USERS } from '../modules/auth/core/_requests';

export interface UserDetails {
    user_id: string;
    user_name: string;
    user_email: string;
    user_mobile: string;
    user_dob: string;
    user_doj: string;
    user_address: string;
    user_role: string;
    user_pic: string;
    user_status: string;
    user_created_on: string;
    user_created_by: string;
    user_department: string;
}

const AllUser = () => {
    const [userData, setUserData] = useState<UserDetails[]>([]);

    const fetchUserData = async (all_users: any) => {
        try {
            const response = await GET_ALL_USERS(all_users);
            setUserData(response.data.user_details);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchUserData("0");
    }, []);

    // Return options directly
    const options = userData.map((item: any) => ({
        value: item.user_id,
        label: item.user_name
    }));

    return options;
}

export default AllUser;
