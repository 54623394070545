import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { UserDeatils_Post, DASHBOARD_GET_STATS } from '../../../../app/modules/auth/core/_requests'
import { HeaderUserMenu } from '../../../partials/layout/header-menus/HeaderUserMenu'
import { AccountDetails, DashboardStats } from '../../../../app/pages/Interface'
import { useAuth } from '../../../../app/modules/auth'
import { KTIcon } from '../../../helpers'
import { Greetings } from './Greetings'
import { HeaderNotificationsMenu } from './HeaderNotification'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'


const btnClass =
  'btn btn-icon btn-custom btn-icon-dark btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'

const btnIconClass = 'fs-2x'
const Navbar = () => {
  const [details, setDetails] = useState<AccountDetails>()
  const { currentUser } = useAuth()
  const [userID] = useState(currentUser?.user_id || '')
  const [userToken] = useState(currentUser?.api_token || '')
  const [stats, setStats] = useState<DashboardStats>();  

  const FetchUserDetails = async (verify_user_token: any, user_id: any) => {
    try {
      const response = await UserDeatils_Post(verify_user_token, user_id);
      setDetails(response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  useEffect(() => {
    FetchUserDetails(userToken, userID);
  }, []);

  const fetchStatsData = async (dashboard_stats: any, dashboard_user_id: any) => {
    try {
      const response = await DASHBOARD_GET_STATS(dashboard_stats, dashboard_user_id);
      setStats(response.data.dashboard_stats); 
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };                                           

  useEffect(() => {
    fetchStatsData("0", userID);
  }, []);

  const handleNotificationClick = () => {
    fetchStatsData("0", userID);
  };

  return (
    <>
      <div className="align-self-center fs-4 fw-bold text-light-grey"><Greetings /> <small className='ms-9 text-gray'>{details?.name}</small></div>
      <div className='app-navbar flex-shrink-0'>
        <div className={clsx('app-navbar-item', itemClass)}>
          <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
            <KTIcon iconName='message-text-2' className={btnIconClass} />
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          </div>
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            className={btnClass}
            onClick={handleNotificationClick}
          >
            <KTIcon iconName='notification' className={btnIconClass} />
            <span className="badge rounded-pill notify_number">
              0
            </span>
          </div>
          <HeaderNotificationsMenu stats={stats} />
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className="symbol symbol-50px symbol-circle">
              <span className="symbol-label fs-2 fw-bold bg-primary text-inverse-primary">
                {details?.pic != '' ? <img src={details?.pic} className='w-100 rounded-3' /> : details?.name.charAt(0)}
              </span>
            </div>
          </div>
          {<HeaderUserMenu />}
        </div>
      </div>
    </>

  )
}
export { Navbar }