import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import toast, { Toaster } from "react-hot-toast";
import AdmissionForm from './AdmissionForm'
import { AdmissionDetails, ContactDetails, CourseDetails, LeadDatas, UserDetails } from '../../pages/Interface'
import { ADMISSION_EDIT_DATA, ADMISSION_GET_DATA, ADMISSION_DELETE_DATA, CONTACTS_GET_data, GET_ALL_COURSE, GET_ALL_LEADS, GET_ALL_USERS } from '../auth/core/_requests'
import { useParams } from 'react-router-dom'
import Pagination from '../../pages/Pagination'
import Loader from '../../pages/Loader';
import Swal from 'sweetalert2';
import AdmissionEdit from './AdmissionEdit';
import AdmissionView from './AdmissionView';
import { useAuth } from '../auth';
import PaginationNew from '../../pages/PaginationNew';
import DataTable from 'react-data-table-component';


const Admission = () => {
  const { currentUser } = useAuth()
  const [userRole] = useState(currentUser?.user_role || '')
  const { admission_status } = useParams<{ admission_status: string }>();
  const [refreshAdmission, setRefreshAdmission] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [admissionModal, setAdmissionModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState<{ value: string; label: string }>();
  const [selectedOption, setSelectedOption] = useState('Contact');
  const [taskAssignby, setTaskAssignby] = useState<{ value: string; label: string }>();
  const [leadsOwnerSelected, setLeadsOwnerSelected] = useState<{ value: string; label: string }>();
  const [courseData, setCourseData] = useState<CourseDetails[]>([]);
  const [customers, setCustomers] = useState<ContactDetails[]>([]);
  const [leadsData, setLeadsData] = useState<LeadDatas[]>([]);
  const [userData, setUserData] = useState<UserDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [admissionData, setAdmissionData] = useState<AdmissionDetails[]>([]);
  const [admissionView, setAdmissionView] = useState<AdmissionDetails[]>([]);
  const [appliedUnivercity, setAppliedUnivercity] = useState<AdmissionDetails[]>([]);
  console.log(admissionData,"admissionData")
  const [currentPage, setCurrentPage] = useState(1);
  const [showPerPage, setShowPerPage] = useState(10);
  const [totalAdmissionData, setTotalAdmissionData] = useState<AdmissionDetails[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState(admission_status)
  const [filterSource, setFilterSource] = useState('')
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const [initialEditValues, setInitialEditValues] = useState(
    {
      admission_id_upd: '',
      admission_exam_status_upd: '',
      admission_for_course_upd: '',
      admission_course_fee_upd: '',
      admission_student_mobile_upd: '',
      admission_student_email_upd: '',
      admission_processing_team_upd: '',
      admission_country_interested_upd: '',
      admission_country_name_upd: '',
      admission_agent_upd: '',
      admission_course_intereseted_upd: '',
      admission_remarks_upd: '',
      admission_fee_upd: '',
      admission_status_upd: '',
    }
  )

  const UserDetails: any = localStorage.getItem('kc-auth')
  const userDetailsObject = JSON.parse(UserDetails);
  const userId = userDetailsObject?.user_id


  const handleOpenAdmission = () => {
    setAdmissionModal(true)
    setTaskAssignby(null as any)
  }

  const handleTaskAssign = (selected: any) => {
    setLeadsOwnerSelected(selected);
  };

  const toggleExpand = (id: any) => {
    setExpandedItemId(prevId => (prevId === id ? null : id));
    setShowAllTags(!showAllTags)
  };

  const fetchUserData = async (all_users: any) => {
    try {
      const response = await GET_ALL_USERS(all_users);
      setUserData(response.data.user_details);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  useEffect(() => {
    fetchUserData("0");
  }, []);

  const fetchCourseData = async (all_courses: any) => {
    try {
      const response = await GET_ALL_COURSE(all_courses);
      setCourseData(response.data.course_details);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  useEffect(() => {
    fetchCourseData("0");
  }, []);

  const fetchContactsList = async (all_contacts: any) => {
    try {
      const response = await CONTACTS_GET_data(all_contacts);
      setCustomers(response.data.contact_details);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  useEffect(() => {
    fetchContactsList("0");
  }, []);

  const fetchLeadsData = async (all_leads: any) => {
    try {
      const response = await GET_ALL_LEADS(all_leads);
      setLeadsData(response.data.leads_details);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  useEffect(() => {
    fetchLeadsData("0",);
  }, []);

  const courseOptions = courseData.map((course: any) => ({
    value: course.course_id,
    label: course.course_name
  }));

  const contactOptions = customers.map((item: any) => ({
    value: item.contact_id,
    label: `${item.contact_first_name} ${item.contact_last_name} ${item.contact_tag_sep}`, // Include subLabel here
    displayLabel: `${item.contact_first_name} ${item.contact_last_name}`,
    subLabel: item.contact_tag_sep,
  }));

  const leadsOptions = leadsData.map((item: any) => ({
    value: item.leads_id,
    label: `${item.leads_first_name} ${item.leads_last_name} ${item.leads_tags_sep}`, // Include subLabel here
    displayLabel: `${item.leads_first_name} ${item.leads_last_name}`,
    subLabel: item.leads_tags_sep,
  }));

  const CustomOption = (props: any) => {
    const { innerRef, innerProps, data } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ padding: '10px' }}>
        <div>{data.displayLabel}</div>
        <div style={{ fontSize: '12px', color: '#ff6a56' }}>
          {data.subLabel ? "Tags :" : ""} {data.subLabel}
        </div>
      </div>
    );
  };

  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
    setTaskAssignby(null as any)
  };

  const handleRelatedChange = (selected: any) => {
    setTaskAssignby(selected);
  };

  const FetchAdmissionList = async (get_all_admission: any, ac_uid: any) => {
    try {
      const response = await ADMISSION_GET_DATA(get_all_admission, ac_uid);
      setAdmissionData(response.data.admission_details);
      setAppliedUnivercity(response.data.admission_details[0].applied_universities)
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching admission:', error);
    }
  };

  useEffect(() => {
    FetchAdmissionList("0", userId);
  }, []);

  const RefreshAdmissionData = () => {
    setRefreshAdmission(true);
    FetchAdmissionList("0", userId)
      .then(response => {
        setRefreshAdmission(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  const handleViewAdmission = async (admission_id: any, ac_uid: any) => {
    try {
      setLoadingEdit(true)
      const response = await ADMISSION_EDIT_DATA(admission_id, ac_uid);
      setAdmissionView(response.data.admission_details[0])
      setAppliedUnivercity(response.data.admission_details[0].applied_universities)
      const selectSegmentTab = document.getElementById('document_sub');
      if (selectSegmentTab) {
        selectSegmentTab.click();
      }
    } catch (error) {
      console.error('Error fetching admission:', error);
    } finally {
      setLoadingEdit(false);
    }
  }

  const handleEditAdmission = async (admission_id: any, ac_uid: any) => {
    setLoadingEdit(true)
    setShowEditModal(true)
    try {
      const response = await ADMISSION_EDIT_DATA(admission_id, ac_uid);
      setLoadingEdit(false)
      const initialData = response.data.admission_details

      const leadsOwnerResponse = response.data.admission_details[0].admission_lead_from;
      const leadOwnerValues = leadsOwnerResponse
      let selectedLeadFrom: any = null;
      selectedLeadFrom = userData.find((item: any) => item.user_name === leadOwnerValues);
      if (selectedLeadFrom) {
        setLeadsOwnerSelected({
          value: selectedLeadFrom.user_id,
          label: selectedLeadFrom.user_name
        });
      }

      const CourseSelectName = response.data.admission_details[0].admission_for_course;
      let selectedOption: any = null;
      selectedOption = courseData.find((item: any) => item.course_id === CourseSelectName);
      if (selectedOption) {
        setSelectedValue({
          value: selectedOption.course_id,
          label: selectedOption.course_name
        });
      }
      const TaskReletedSelect = response.data.admission_details[0].admission_source_from;
      setSelectedOption(TaskReletedSelect)
      const TaskReletedTo = response.data.admission_details[0].admission_student_name.trim().toLowerCase();

      let taskRelatedContact = customers.find(item => {
        const contactFirstName = `${item.contact_first_name} ${item.contact_last_name}`.trim().toLowerCase();
        return contactFirstName === TaskReletedTo;
      });

      let taskRelatedLead = leadsData.find(item => {
        const leadsName = `${item.leads_first_name} ${item.leads_last_name}`.trim().toLowerCase();
        return leadsName === TaskReletedTo;
      });

      if (TaskReletedSelect === "Contact") {
        if (taskRelatedContact) {
          setTaskAssignby({
            value: taskRelatedContact.contact_id,
            label: `${taskRelatedContact.contact_first_name} ${taskRelatedContact.contact_last_name}`
          });
        }
      } else {
        if (taskRelatedLead) {
          setTaskAssignby({
            value: taskRelatedLead.leads_id,
            label: `${taskRelatedLead.leads_first_name} ${taskRelatedLead.leads_last_name}`
          });
        }
      }
      setInitialEditValues({
        admission_id_upd: initialData.length > 0 ? initialData[0].admission_id : '',
        admission_exam_status_upd: initialData.length > 0 ? initialData[0].admission_exam_status : '',
        admission_for_course_upd: initialData.length > 0 ? initialData[0].admission_for_course : '',
        admission_course_fee_upd: initialData.length > 0 ? initialData[0].admission_course_fee : '',
        admission_student_mobile_upd: initialData.length > 0 ? initialData[0].admission_student_mobile : '',
        admission_student_email_upd: initialData.length > 0 ? initialData[0].admission_student_email : '',
        admission_processing_team_upd: initialData.length > 0 ? initialData[0].admission_processing_team : '',
        admission_country_interested_upd: initialData.length > 0 ? initialData[0].admission_country_interested : '',
        admission_country_name_upd: initialData.length > 0 ? (
          initialData[0].admission_country_interested === "Others" ? initialData[0].admission_other_country || '' : ''
      ) : '',
        admission_agent_upd: initialData.length > 0 ? initialData[0].admission_agent : '',
        admission_course_intereseted_upd: initialData.length > 0 ? initialData[0].admission_course_intereseted : '',
        admission_remarks_upd: initialData.length > 0 ? initialData[0].admission_remarks : '',
        admission_fee_upd: initialData.length > 0 ? initialData[0].admission_fee : '',
        admission_status_upd: initialData.length > 0 ? initialData[0].admission_status : '',
      })
    } catch (error) {
      console.error('Error fetching admission:', error);
    }
  };

  const handleCallLogDelete = (admission_delete_id: string, admission_deleted_by: string) => {
    const handleDeleteEvent = async () => {
      try {
        const response = await ADMISSION_DELETE_DATA(admission_delete_id, admission_deleted_by);
        const filterdata = admissionData.filter(
          (item) => item.admission_id !== admission_delete_id
        );
        setAdmissionData(filterdata);
      } catch (error) {
        console.error("Error deleting admission:", error);
      }
    };
    handleDeleteEvent();
  };

  const handleDeleteConfirmation = (admission_delete_id: string, admission_deleted_by: string) => {
    Swal.fire({
      title: 'Are you sure!',
      text: "Delete this admission..?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
        cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Call your delete function here
        handleCallLogDelete(admission_delete_id, admission_deleted_by);
        Swal.fire(
          'Deleted!',
          'Your admission has been deleted.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Canceling will not save any changes :)',
          'error'
        )
      }
    });
  };

  const handleItemsPerPageChange = (perPage: number) => {
    setShowPerPage(perPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  useEffect(() => {
    const filtered = admissionData?.filter((app: any) => {
      const appData = `${app.admission_id} ${app.admission_student_name} ${app.admission_student_mobile} ${app.admission_for_course} ${app.admission_creator_name}`.toLowerCase();
      return appData.includes(searchQuery.toLowerCase());
    });
    setTotalAdmissionData(filtered);
  }, [searchQuery, admissionData]);

  const sortByColumn = (column: string) => {
    const sortedData = [...admissionData];

    if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
      sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
      setSortOrder('desc');
    } else {
      sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
      setSortOrder('asc');
    }
    setSortColumn(column);
    setAdmissionData(sortedData);
  };

  useEffect(() => {
    const filteredOrders = admissionData.filter((item) => {
      if (!filterStatus && !filterSource) {
        return true;
      }
      return (
        (!filterStatus || item.admission_status == filterStatus) &&
        (!filterSource || item.admission_exam_status == filterSource)
      );
    });
    setTotalAdmissionData(filteredOrders);
  }, [filterStatus, filterSource, admissionData]);

  const handleStatusFilter = (e: any) => {
    setFilterStatus(e.target.value);
  };

  const handleExamTypeFilter = (e: any) => {
    setFilterSource(e.target.value);
  };

  const itemsPerPage = 10;
  const totalItems = admissionData.length;
  const filterDataTotal = totalAdmissionData.length
  const totalPages = Math.ceil(totalItems / showPerPage);
  const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
  const startIndex = (currentPage - 1) * showPerPage;
  const endIndex = startIndex + showPerPage;
  const AdmissionData = totalAdmissionData.slice(startIndex, endIndex);

  const columns: any = [
    {
      name: 'S.No',
      selector: (row: any) => row.admission_id,
      sortable: true,
      minWidth: '80px',
      cell: (row: any) => (row.admission_id),
      style: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: 'inherit',
      },
    },
    {
      name: 'Lead Counselor',
      selector: (row: any) => row.admission_lead_from || "-",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Processing team',
      selector: (row: any) => row.admission_processing_team || "-",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Name',
      selector: (row: any) => row.admission_student_name || "-",
      sortable: true,
      cell: (row: any) => (
        <div>
          {row.admission_student_name || "-"}
          {/* {typeof row.admission_tags === 'string' && (() => {
            const tags = row.admission_tags.replace(/[\[\]"]+/g, '').split(',').map((tag: any) => tag.trim());
            return (
              <>
                {tags.length > 0 && (
                  <>
                    <i className='bi bi-tag text-dark fs-4 pt-1' /> <span className="badge badge-light-primary me-2 p-1">{tags[0]}</span>
                  </>
                )}
                <div className="dropdown d-inline">
                  <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    {tags.length > 1 && (
                      <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(row.admission_id)}>
                        {expandedItemId === row.admission_id ? "Hide" : "+"}
                        {expandedItemId === row.admission_id ? "" : tags.length - 1}
                      </span>
                    )}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {tags.slice(1).map((tag: any) => (
                      <li key={tag}>
                        <button className="dropdown-item" type="button">
                          {tag}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            );
          })()} */}
        </div>
      ),
      style: {
        position: 'sticky',
        left: 70,
        zIndex: 1,
        backgroundColor: 'inherit',
      },
    },
    {
      name: 'Contact',
      selector: (row: any) => row.admission_student_mobile || "-",
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: any) => row.admission_student_email || "-",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Country',
      selector: (row: any) => row.admission_country_interested === "Others" ? row.admission_other_country : row.admission_country_interested || "-",
      sortable: true,
      minWidth: '250px',
    },
    {
      name: 'Agent',
      selector: (row: any) => row.admission_agent || "-",
      sortable: true,
    },
    {
      name: 'Applied University',
      sortable: true,
      minWidth: '250px',
      cell: (row: any) => (
        <div>
          {/* Display only the first university's name and status */}
          {row.applied_universities.length > 0 ? (
            <div className="position-relative">
              <strong>{row.applied_universities[0].applied_university_name || "-"}</strong> - {row.applied_universities[0].applied_status || "-"}
    
              {/* Dropdown for full details */}
              <div className="dropdown position-absolute start-0 mt-n2">
                <button className="btn btn-sm text-primary btn-link text-decoration-none" type="button" id={`dropdownMenu-${row.admission_id}`} data-bs-toggle="dropdown" aria-expanded="false">
                  view more
                </button>
                <ul className="dropdown-menu" aria-labelledby={`dropdownMenu-${row.admission_id}`}>
                  {row.applied_universities.map((university: any, index: number) => (
                    <li key={index}>
                      <button className="dropdown-item" type="button">
                        <strong>{university.applied_university_name || "-"}</strong> - {university.applied_status || "-"}
                        <br />
                        <small>Applied on: {university.applied_on || "-"}</small>
                        <br />
                        <small>Applied by: {university.applied_by || "-"}</small>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            "-"
          )}
        </div>
      ),
    },    
    {
      name: 'Program',
      selector: (row: any) => row.admission_course_intereseted || "-",
      sortable: true,
      minWidth: '250px',
    },
    {
      name: 'Offer Letter',
      selector: (row: any) => row.admission_offer_letter == "1" ? "Conditional Offer" : row.admission_offer_letter == "2" ? "Unconditional Offer" : '',
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Funding method',
      selector: (row: any) => row.admission_fund_method || "-",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Loan Process',
      selector: (row: any) => row.admission_loan_process_type || "-",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Deposit Paid',
      selector: (row: any) => row.admission_deposit_amount || "-",
      sortable: true,
    },
    {
      name: 'Date of Payment',
      selector: (row: any) => row.admission_deposit_date || "-",
      sortable: true,
    },
    {
      name: 'CAS/I-20',
      selector: (row: any) => row.admission_cas_status || "-",
      sortable: true,
    },
    {
      name: 'Visa Status',
      selector: (row: any) => row.admission_visa_status || "-",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Created by',
      selector: (row: any) => row.admission_created_by,
      minWidth: '250px',
      sortable: true,
      cell: (row: any) => (
        <div className={`${sortColumn === 'admission_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_created_on')}>
          {row.admission_created_by}
          <span className="fw-semibold d-block fs-7">{row.admission_created_on}</span>
        </div>
      ),
    },
    {
      name: 'Actions',
      cell: (row: any) => (
        <div>
          <div className="dropdown">
            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-three-dots fs-5"></i>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
              <li>
                <button className="dropdown-item" type="button" onClick={() => handleEditAdmission(row.admission_id, userId)}>Edit</button>
              </li>
              <li>
                <button className="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => handleViewAdmission(row.admission_id, userId)}>Preview</button>
              </li>
              {userRole !== "3" && (
                <li>
                  <button className="dropdown-item" type="button" onClick={() => handleDeleteConfirmation(row.admission_id, userId)}>Delete</button>
                </li>
              )}
            </ul>
          </div>
        </div>
      ),
      style: {
        position: 'sticky',
        right: -30,
        zIndex: 1,
        backgroundColor: 'white',
      },
    },
  ];
  return (
    <>
      <div className='card bg_none'>
        <div className='card-header p-0 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Admission</span>
            <span className='mt-1 fw-bold fs-7'>Total {admissionData.length} Admission</span>
          </h3>
          <div className='card-toolbar'>
            <button className="btn btn-primary refresh_icon me-5" onClick={RefreshAdmissionData}>{!refreshAdmission ? (
              <i className="bi bi-arrow-clockwise fs-2"></i>
            ) : (
              <i className="spinner-border spinner-border-sm fs-2"></i>
            )} </button>
            <button className="btn btn-primary refresh_icon me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              <i className="las la-filter fs-2" />
            </button>
            <div>
              <button className='btn btn-primary' onClick={handleOpenAdmission}>
                <KTIcon iconName='plus-circle' className='fs-2' />
                Add Admission
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="collapse" id="collapseExample">
        <div className='card bg_none'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
            </h3>
            <div className='card-toolbar'>
              <div className='me-5'>
                <select className='form-select' onChange={handleExamTypeFilter}
                  value={filterSource}>
                  <option value="">Exam Status</option>
                  <option value="1">Exam Cleared</option>
                  <option value="2">Not Cleared</option>
                </select>
              </div>
              <div className='me-5'>
                <select
                  className='form-select'
                  onChange={handleStatusFilter}
                  value={filterStatus}
                >
                  <option value="">Collage Status</option>
                  <option value="1">Joined</option>
                  <option value="2">On Going </option>
                  <option value="3">Cancelled</option>
                  <option value="4">Completed</option>
                </select>
              </div>
              <div className="d-flex align-items-center position-relative">
                <div className="input-group">
                  <input type="search" placeholder='Search Name...'
                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control w-50" />
                  <button type="button" className="btn btn-primary">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />

      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (AdmissionData.length > 0 ? (
        <div className={`mt-5 table-responsive ${refreshAdmission ? 'd opacity-50' : ''}`} >
          {/* <table className='table gs-0 gy-4'>
            <thead className='table_bg_color'>
              <tr className='fw-bold fs-6'>
                <th className={`ps-5 ${sortColumn === 'admission_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_id')}>
                  S.No
                </th>
                <th className=''>
                  Lead Counselor
                </th>
                <th>
                  Processing team
                </th>
                <th>
                  Name
                </th>
                <th>
                  Contact
                </th>
                <th>
                  Email
                </th>
                <th>
                  Country
                </th>
                <th>
                  Agent
                </th>
                <th>
                  Applied University
                </th>
                <th>
                  Program
                </th>
                <th>
                  Offer Letter
                </th>
                <th>
                  Funding method
                </th>
                <th>
                  Loan Process
                </th>
                <th>
                  Deposit Paid
                </th>
                <th>
                  Date of Payment
                </th>
                <th>
                  CAS/I-20
                </th>
                <th>
                  Visa Status
                </th>
                <th className={`${sortColumn === 'admission_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_created_on')}>
                  Created by
                </th>
                <th className='pe-5'>Actions</th>
              </tr>
            </thead>
            <th className={`${sortColumn === 'admission_student_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_student_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_student_name')}>
                  Name
                </th>
                <th className={`w-150px ${sortColumn === 'admission_for_course' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_for_course' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_for_course')}>
                  Language Course
                </th>
                <th className={`${sortColumn === 'admission_course_intereseted' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_course_intereseted' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_course_intereseted')}>
                  Course
                </th>
                <th className={`${sortColumn === 'admission_exam_status' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_exam_status' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_exam_status')}>
                  Exam Status
                </th>
                <th className={`w-150px ${sortColumn === 'admission_country_interested' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_country_interested' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_country_interested')}>
                  Course Interested
                </th>
                <th>
                  Deposit
                </th>
                <th className={`w-150px ${sortColumn === 'admission_status' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'admission_status' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('admission_status')}>
                  Collage  Status
                </th>

            <tbody className='bg-light'>
              {AdmissionData.map((admission, index) => (
                <tr key={index} className='border-bottom  border-gray-300'>
                  <td className='text-dark fw-bold text-hover-primary fs-6 ps-5'>{admission.admission_id}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_lead_from ? admission.admission_lead_from : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_processing_team ? admission.admission_processing_team : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {admission.admission_student_name ? admission.admission_student_name : "-"}
                    {typeof admission.admission_tags === 'string' ? (() => {
                      const tags = admission.admission_tags.replace(/[\[\]"]+/g, '').split(',').map(tag => tag.trim());
                      return (
                        <>
                          {tags.length > 0 && (
                            <>
                              <KTIcon iconName='price-tag' className='text-dark fs-4 pt-1' /> <span className="badge badge-light-primary me-2 p-1">{tags[0]}</span>
                            </>
                          )}
                          <div className="dropdown d-inline">
                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                              {tags.length > 1 && (
                                <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(admission.admission_id)}>
                                  {expandedItemId === admission.admission_id ? "Hide" : "+"}
                                  {expandedItemId === admission.admission_id ? "" : tags.length - 1}
                                </span>
                              )}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                              {showAllTags && tags.slice(1).map(tag => (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    <span key={tag}>{tag}</span>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      );
                    })() : '-'}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_student_mobile ? admission.admission_student_mobile : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_student_email ? admission.admission_student_email : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_course_intereseted ? admission.admission_course_intereseted : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>-</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>-</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_course_intereseted ? admission.admission_course_intereseted : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_offer_letter ? admission.admission_offer_letter : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>-</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_loan ? admission.admission_loan : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>-</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>-</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>-</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>-</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {admission.admission_student_name ? admission.admission_student_name : "-"} <br />
                    <span className="fw-semibold d-block fs-7"><i className="bi bi-telephone text-dark fs-6"></i> {admission.admission_student_mobile ? admission.admission_student_mobile : "-"}</span>
                    {typeof admission.admission_tags === 'string' ? (() => {
                      const tags = admission.admission_tags.replace(/[\[\]"]+/g, '').split(',').map(tag => tag.trim());
                      return (
                        <>
                          {tags.length > 0 && (
                            <>
                              <KTIcon iconName='price-tag' className='text-dark fs-4 pt-1' /> <span className="badge badge-light-primary me-2 p-1">{tags[0]}</span>
                            </>
                          )}
                          <div className="dropdown d-inline">
                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                              {tags.length > 1 && (
                                <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(admission.admission_id)}>
                                  {expandedItemId === admission.admission_id ? "Hide" : "+"}
                                  {expandedItemId === admission.admission_id ? "" : tags.length - 1}
                                </span>
                              )}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                              {showAllTags && tags.slice(1).map(tag => (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    <span key={tag}>{tag}</span>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      );
                    })() : '-'}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {admission.admission_for_course ? admission.admission_for_course : "-"}
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {admission.admission_for_course_type ? admission.admission_for_course_type : "-"}
                    </span>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {admission.admission_exam_status === '1' ? (
                      <span className='badge badge-success text-white'>Exam Cleared</span>
                    ) : admission.admission_exam_status === '2' ? (
                      <span className='badge badge-danger text-white'>Not Cleared</span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {admission.admission_course_intereseted ? admission.admission_course_intereseted : "-"}<br />
                    <small> {admission.admission_country_interested ? admission.admission_country_interested : "-"} </small>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {admission.admission_deposit_amount ? admission.admission_deposit_amount : "-"}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {admission.admission_status === '1' ? (
                      <span className='badge badge-info text-white'>Joined</span>
                    ) : admission.admission_status === '2' ? (
                      <span className='badge badge-warning text-white'>On Going </span>
                    ) : admission.admission_status === '3' ? (
                      <span className='badge badge-danger text-white'>Cancelled</span>
                    ) : admission.admission_status === '4' ? (
                      <span className='badge badge-success text-white'>Completed</span>
                    ) : ""}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{admission.admission_created_by}
                    <span className="fw-semibold d-block fs-7">{admission.admission_created_on}</span>
                  </td>
                  <td style={{ paddingTop: "10px" }}>
                    <div className="dropdown">
                      <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-three-dots fs-5"></i>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleEditAdmission(admission.admission_id, userId)}
                          >
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                            onClick={() => handleViewAdmission(admission.admission_id, userId)}
                          >
                            Preview
                          </button>
                        </li>
                        {userRole != "3" && (
                          <>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleDeleteConfirmation(admission.admission_id, userId)}
                              >
                                Delete
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
          {/* <div className={`mt-5 table-responsive ${refreshAdmission ? 'opacity-50' : ''}`}> */}
          <DataTable
            columns={columns}
            data={AdmissionData}
            highlightOnHover
            pointerOnHover
            responsive
            customStyles={{
              headCells: {
                style: {
                  fontWeight: 'bold',
                  fontSize: '16px',
                  backgroundColor: '#fff',
                },
              },
              cells: {
                style: {
                  padding: '25px',
                },
              },
            }}
          />
          {/* </div> */}
          {filterStatus || filterSource || searchQuery ? (
            <PaginationNew
              currentPage={currentPage}
              totalPages={filterTotalPage}
              itemsPerPage={itemsPerPage}
              handlePageChange={setCurrentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          ) : (
            <PaginationNew
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={showPerPage}
              handlePageChange={setCurrentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      ) :
        <div style={{ minHeight: "55vh" }} className='d-flex align-items-center justify-content-center'>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className='text-center'>
                  <img src={toAbsoluteUrl('/media/stock/admission.png')} className='w-250px' />
                  <h4>No Admission Found</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`modal${admissionModal ? ' show' : ''}`} role="dialog" style={{ display: admissionModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <AdmissionForm setAdmissionModal={setAdmissionModal} selectedValue={selectedValue} setSelectedValue={setSelectedValue} courseOptions={courseOptions}
            courseData={courseData} selectedOption={selectedOption} handleSelectChange={handleSelectChange} leadsOptions={leadsOptions} contactOptions={contactOptions}
            taskAssignby={taskAssignby} setTaskAssignby={setTaskAssignby} leadsData={leadsData} customers={customers} FetchAdmissionList={FetchAdmissionList} CustomOption={CustomOption}
            handleTaskAssign={handleTaskAssign} leadsOwnerSelected={leadsOwnerSelected} setLeadsOwnerSelected={setLeadsOwnerSelected}
          />
        </div>
      </div>

      {showEditModal && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <AdmissionEdit setShowEditModal={setShowEditModal} selectedValue={selectedValue} setSelectedValue={setSelectedValue} courseOptions={courseOptions}
              courseData={courseData} selectedOption={selectedOption} handleSelectChange={handleSelectChange} leadsOptions={leadsOptions} contactOptions={contactOptions}
              CustomOption={CustomOption} taskAssignby={taskAssignby} leadsData={leadsData} customers={customers} initialEditValues={initialEditValues}
              loadingEdit={loadingEdit} FetchAdmissionList={FetchAdmissionList} setTaskAssignby={setTaskAssignby}
              leadsOwnerSelected={leadsOwnerSelected} handleTaskAssign={handleTaskAssign} setLeadsOwnerSelected={setLeadsOwnerSelected}
            />
          </div>
        </div>
      )}

      <div className="offcanvas offcanvas-end" style={{ width: '80%', background: "#F0F0F0" }} tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <AdmissionView loadingEdit={loadingEdit} admissionView={admissionView} handleViewAdmission={handleViewAdmission} 
        appliedUnivercity={appliedUnivercity} FetchAdmissionList={FetchAdmissionList}
        />
      </div>
    </>
  )
}

export default Admission