/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Loader from '../Loader'
import { DashboardStats } from '../Interface';
import { useAuth } from '../../modules/auth';


type Props = {
  className: string;
  stats: DashboardStats | undefined;
  loading: boolean
}

const StaffLog: React.FC<Props> = ({ className, stats, loading }) => {
  const { currentUser } = useAuth()
  const [userRole] = useState(currentUser?.user_role || '')

  const getColorForIndex = (index: number) => {
    const colors = ['#50CD89', '#7239EA', '#F1416C', '#FFC700']; // Primary, Danger, Success, Warning
    return colors[index % colors.length];
  };

  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{userRole == '3' ? "Activity Log" : "Staff Activity Log"}</span>
          <span className='text-muted fw-semibold fs-7'>Today</span>
        </h3>
      </div>

      <div className='card-body pt-5' style={{ overflowY: 'auto', maxHeight: '500px' }}>
        <>
          {loading ? (
            <Loader />
          ) : (
            stats?.log_details?.logs.length === 0 ? (
              <div style={{ minHeight: "45vh" }} className='d-flex align-items-center justify-content-center'>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 offset-md-3">
                      <div className='text-center'>
                        <img src={toAbsoluteUrl('/media/stock/admission.png')} className='w-150px' />
                        <h4>No Log Found</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              stats?.log_details?.logs.map((log, index) => (
                <div className='timeline-label'>
                  <div className='timeline-item' key={index}>
                    <div className='timeline-label fw-bold text-gray-800 fs-6'>{log.log_created_time.substring(0, 5)}</div>
                    <div className='timeline-badge'>
                      <i className='fa fa-genderless fs-1' style={{ color: getColorForIndex(index) }}></i>
                    </div>
                    <div className='timeline-content fw-bold text-gray-800 ps-3 pb-5'>
                      ID : <span className='badge bg-primary fs-9 text-white'># {log.log_id}</span> <br />
                      Message : <span>{log.log_message}</span> <br />
                      Type : <span>{log.log_type}</span> <br />
                      Update by : <span>  {log.log_created_by} </span>
                    </div>
                  </div>
                </div>
              ))))}
        </>
      </div>

    </div>
  )
}

export { StaffLog }
