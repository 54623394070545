import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikValues } from 'formik';
import * as Yup from 'yup';
import { COURSE_POST_DATA } from '../auth/core/_requests';
import { KTIcon } from '../../../_metronic/helpers';
import toast from "react-hot-toast";


interface Image {
    file: File;
    preview: string;
}
const CourseForm = ({ setCourseModal,fetchCourseData }: any) => {
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState<Image[]>([]);
    const [uploadError, setUploadError] = useState<string[]>([]);

    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.user_id

    const validationSchema = Yup.object().shape({
        course_name: Yup.string().required('Course Name is required'),
        course_type: Yup.string().required('Course Type is required'),
        course_fee: Yup.string().required('Course Fees is required'),
    }); 

    const initialValues = {
        course_name: '',
        course_type: '',
        course_fee: '',
        course_remarks: '',
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    const ALLOWED_FORMATS = ['image/png', 'image/jpeg', 'video/mp4', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files) as File[];
            const errors: string[] = [];
            const selectedImages = files.filter(file => {
                const isValidFormat = ALLOWED_FORMATS.includes(file.type);
                const isValidSize = file.size <= MAX_FILE_SIZE;
                if (!isValidFormat) {
                    errors.push(`File ${file.name} has invalid format`);
                }
                if (!isValidSize) {
                    errors.push(`File ${file.type} exceeds the maximum allowed size of 5MB`);
                }
                return isValidFormat && isValidSize;
            }).map((file) => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
                size: file.size,
            }));

            setUploadError(errors);

            if (selectedImages.length > 0) {
                setImages((prevImages) => [...prevImages, ...selectedImages]);
            }
        }
    };
 
    const formatFileSize = (bytes: number) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleRemoveImage = (index: number) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('course_name', values.course_name);
            formData.append('course_type', values.course_type);
            formData.append('course_fee', values.course_fee);
            formData.append('course_remarks', values.course_remarks);
            formData.append('course_created_by', user_id);
            images.forEach((image) => {
                formData.append('course_material[]', image.file);
            });
            const response = await COURSE_POST_DATA(formData);  
            if (response.data.message === 'success') {
                toast.success("Course Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setCourseModal(false);
                setLoading(false);
                fetchCourseData("0");
                actions.resetForm()
            } else {
                toast.error("Failed to add course", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            } 
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    };

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Create Course :</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setCourseModal(false)}></button>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {() => (
                    <Form>
                        <div className="modal-body form_content">
                            <div className="form-group mb-3">
                                <label htmlFor="course_name" className="form-label mb-3 required">Course Name</label>
                                <Field name="course_name" type="text" className="form-control form-control-sm" />
                                <ErrorMessage name="course_name" component="div" className="text-danger" />
                            </div>
                            <div className="form-group mb-3">
                                <label className="form-label mb-3 required" htmlFor="course_type">Course Type</label>
                                <Field as="select" name="course_type" aria-label="Select example" className="form-select form-select-sm">
                                    <option value="">Select a type</option>
                                    <option value="Online">Online</option>
                                    <option value="Offline">Offline</option>
                                </Field>
                                <ErrorMessage name="course_type" component="div" className="text-danger" />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="course_fee" className="form-label mb-3">Course Fee</label>
                                <Field name="course_fee" type="text" className="form-control form-control-sm" />
                                <ErrorMessage name="course_fee" component="div" className="text-danger" />
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="course_remarks" className="form-label mb-3">Course Remarks</label>
                                <Field as="textarea" name="course_remarks" type="text" className="form-control form-control-sm" />
                            </div>  

                            <div className='mb-5'>
                                <label className="form-label mb-5">Course Material (Upload Documents - Multiple)</label>
                                <label className="file-attachment text-primary">
                                    <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                    <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                </label>
                            </div>
                            {uploadError.length > 0 && (
                                <div className="text-danger">
                                    {uploadError.map((error, index) => (
                                        <div key={index}>{error}</div>
                                    ))}
                                </div>
                            )}

                            {images.length > 0 ? (
                                <table className="table table-hover table-sm">
                                    <thead>
                                        <tr className='fw-bold fs-6'>
                                            <th className='ps-8'>File Name</th>
                                            <th className='text-center'> Size</th>
                                            <th className='text-center'>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {images.map((image, index) => (
                                            <tr key={index}>
                                                <td className='ps-8'>
                                                    {image.file.name}
                                                </td>
                                                <td className='text-center'>
                                                    {formatFileSize(image.file.size)}
                                                </td>
                                                <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                    <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) :
                                <>

                                </>
                            }
                        </div>
                        <div className="modal-footer">
                            <div className='d-flex justify-content-end'>
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Submit'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CourseForm;
