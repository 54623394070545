import React, { useState } from 'react'
import Loader from '../../../../pages/Loader'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import Pagination from '../../../../pages/Pagination';
import Swal from 'sweetalert2';
import { whatsapp_broadcast_segment_POST } from '../../../auth/core/_requests';

const Segment = ({ handleBackButtonClick, isLoading, setLoading, setSelectedCheckboxes, setSelectedSegmentId, setSelectedNames,
    selectedCheckboxes, handleDeleteConfirmation, customerId, OpenFilterModel, segmentData, isApplied, customers,
    segmentName, setSegmentName, setErrorShow, setCustomers, details, fetchSegmentsData, loading, errorShow,handleRecipientData
}: any) => {

    const [currentSegmentPage, setCurrentSegmentPage] = useState<number>(1);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: string, segmentId: string, orderPersonName: string) => {
        if (event.target.checked) {
            setSelectedCheckboxes((prevSelected: any) => [...prevSelected, value]);
            setSelectedSegmentId((prevSelectedIds: any) => [...prevSelectedIds, segmentId]);
            setSelectedNames((prevSelectedNames: any) => [...prevSelectedNames, orderPersonName]);
        } else {
            setSelectedCheckboxes((prevSelected: any) => prevSelected.filter((item: any) => item !== value));
            setSelectedSegmentId((prevSelectedIds: any) => prevSelectedIds.filter((id: any) => id !== segmentId));
            setSelectedNames((prevSelectedNames: any) => prevSelectedNames.filter((name: any) => name !== orderPersonName));
        }
    };

    const handleSegmentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSegmentName(event.target.value);
        setErrorShow('');
    };

    const handleDeleteRow = (index: any) => {
        const updatedCustomers = [...customers];
        const deletedItem = updatedCustomers.splice(index, 1)[0]; // Splice returns an array, we take the first (and only) element
        setCustomers(updatedCustomers);
        Swal.fire({
            icon: 'success',
            title: 'Row Deleted Successfully',
            text: `Deleted: ${deletedItem.order_person_name}`,
        });
    };

    const handleCreateSegment = async () => {
        if (!segmentName) {
            setErrorShow('Please enter a segment name.'); // Set error message
            return; // Exit the function if there's an error
        }
        setLoading(true)
        try {
            const response = await whatsapp_broadcast_segment_POST(
                segmentName,
                customers,
                details?.id || ''
            ); 
            if (response.data.message === 'success') {
                setLoading(false)
                fetchSegmentsData("0");
                const selectSegmentTab = document.getElementById('selectSegmentTab');
                if (selectSegmentTab) {
                    selectSegmentTab.click();
                }
                setTimeout(() => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "bottom",
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast',
                        },
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                    Toast.fire({
                        icon: "success",
                        title: "Segment created successfully"
                    });
                    setSegmentName('');
                    setErrorShow('');
                }, 500);
            } else {
                setLoading(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                });
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
            setLoading(false);
        }
    };
    

    const handlePageSegmentChange = (page: number) => {
        setCurrentSegmentPage(page);
    };

    const itemsPerPage = 5;
    const totalSegmentItems = segmentData.length;
    const totalSegmentPages = Math.ceil(totalSegmentItems / itemsPerPage);
    const SegmentIndex = (currentSegmentPage - 1) * itemsPerPage;
    const SegmentLists = segmentData.slice(SegmentIndex, SegmentIndex + itemsPerPage);

    return (
        <>
            <div className='card p-10'>
                <h5><i className="bi bi-arrow-left fs-3 text-dark pe-3 cursor-pointer" onClick={handleBackButtonClick}></i> Select Segment</h5>
                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mt-5 mb-5 fs-6">
                    <li className="nav-item">
                        <a id="selectSegmentTab" className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_4">
                            Select Segment
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_5">
                            Create Segment
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">

                    <div className="tab-pane fade show active" id="kt_tab_pane_4" role="tabpanel">
                        {isLoading ? (
                            <Loader />
                        ) : (SegmentLists.length > 0 ? (
                            <div className='table-responsive bg-white mt-10 p-5'>
                                <table className='table align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold fs-6 bg-light-primary'>
                                            <th className='btn btn-icon btn-active-color-primary btn-sm ps-4 rounded-start'>
                                            </th>
                                            <th>Segment Name</th>
                                            <th>Total Contacts</th>
                                            <th>Created Date</th>
                                            <th>Created By</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {SegmentLists.map((item: any, index: any) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className='form-check form-check-sm form-check-custom form-check-solid ps-4 mt-4'>
                                                        <input
                                                            className='form-check-input widget-13-check'
                                                            type='checkbox'
                                                            value={item.segment_id}
                                                            onChange={(e) => handleCheckboxChange(e, item.segment_id, item.segment_id, item.segment_customers)}
                                                            checked={selectedCheckboxes.includes(item.segment_id)}
                                                        />
                                                    </div>
                                                </td>
                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item.segment_name}
                                                </td> 
                                                <td onClick={() => handleRecipientData(item?.segment_id)}>
                                                    <span className="d-flex align-items-center text-dark fw-bold text-hover-primary me-5 mb-2">
                                                        <KTIcon iconName='eye' className='fs-1 text-dark me-3 cursor-pointer' />
                                                        {item.segment_customers && JSON.parse(item.segment_customers).length}
                                                    </span>
                                                </td>
                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item.segment_created_on}
                                                </td>
                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item.segment_created_by}
                                                </td>
                                                <td style={{ paddingTop: "10px" }}>
                                                    <div className="dropdown">
                                                        <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="bi bi-three-dots fs-5"></i>
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            <li>
                                                                <button
                                                                    className="dropdown-item"
                                                                    type="button"
                                                                    onClick={() => handleDeleteConfirmation(item.segment_id, customerId)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination
                                    currentPage={currentSegmentPage}
                                    totalPages={totalSegmentPages}
                                    handlePageChange={handlePageSegmentChange}
                                />
                            </div>
                        ) :
                            <div className="d-flex justify-content-center p-10">
                                <div>
                                    <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                                    <h3 className="text-center">No segments found</h3>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                        <div>
                            <h5 className='text-center mt-3'>Please add filters to create  a segment</h5>
                            <div className="btn-group">
                                <button className="btn btn-sm btn-primary" type="button" onClick={OpenFilterModel}>
                                    <KTIcon iconName='filter' className='fs-2' />
                                    Add Filter
                                </button>
                            </div>
                            <div>
                                {isApplied && (
                                    customers.length > 0 ? (
                                        <div className='table-responsive bg-white mt-5 p-5'>
                                            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                                <table className='table align-middle gs-0 gy-4'>
                                                    <thead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                                        <tr className='fw-bold fs-6 bg-light-primary'>
                                                            <th className='btn btn-icon btn-active-color-primary btn-sm ps-4 rounded-start'>
                                                                <KTIcon iconName='trash' className='fs-3 mt-4' />
                                                            </th>
                                                            <th className=''>Name</th>
                                                            <th>Number</th>
                                                            <th>Address-1</th>
                                                            <th>Address-2</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                        {customers.map((item: any, index: any) => (
                                                            <tr key={index}>
                                                                <td className='btn btn-icon btn-active-color-primary btn-sm ps-4' onClick={() => handleDeleteRow(index)}>
                                                                    <KTIcon iconName='trash' className='fs-3' />
                                                                </td>
                                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                                    {item.order_person_name ? item.order_person_name : '-'}
                                                                </td>
                                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                                    {item.order_contact_number}
                                                                </td>
                                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                                    {item.delivery_address_1 ? item.delivery_address_1 : '-'}
                                                                </td>
                                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                                    {item.delivery_address_2 ? item.delivery_address_2 : '-'}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        customers ? (
                                            <div className="d-flex justify-content-center p-10">
                                                <div>
                                                    <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                                    <h3 className="text-center">No customers found</h3>
                                                </div>
                                            </div>
                                        ) : null
                                    )
                                )}
                                <div className='bg-success'>
                                    {isApplied && (
                                        customers.length > 0 ? (
                                            <div className="d-flex align-items-center justify-content-between bg-white mt-10 p-5">
                                                <h2 className="ms-3"><span>{customers.length}</span> Customers</h2>
                                                <div>
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            name='segment_name'
                                                            value={segmentName}
                                                            onChange={handleSegmentNameChange}
                                                            className="form-control w-50 form-control-solid me-3"
                                                            placeholder="Enter Segment Name"
                                                            required
                                                        />
                                                        <button className="btn btn-sm btn-primary" type="button" onClick={handleCreateSegment}>
                                                            {!loading ? 'Create Segment' : (
                                                                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                    {errorShow && <div className="text-danger mt-2">{errorShow}</div>}
                                                </div>
                                            </div>
                                        ) : ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Segment