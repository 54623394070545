import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom' 
import Task from './Task'
import Call from './Call'
 

const TaskPage: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            > 
                <Route
                    path='create-task'
                    element={
                        <>
                            <Task />
                        </>
                    }
                />
                <Route
                    path='create-call'
                    element={
                        <>
                            <Call />
                        </>
                    }
                /> 
            </Route>
        </Routes>
    )
}

export default TaskPage
