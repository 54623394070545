import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import { COURSE_PUT_DATA, COURSE_DOCUMENTS_POST_DATA, COURSE_DOCUMENTS_DELETE_DATA } from '../auth/core/_requests';
import { KTIcon } from '../../../_metronic/helpers';
import Loader from '../../pages/Loader';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';


const CourseEdit = ({ setShowEditModal, loadingEdit, initialEditValues, handleImageChange, handleRemoveImage,
    uploadError, images, setImages, filesData, setFilesData, fetchCourseData }: any) => {
    const [loading, setLoading] = useState(false)
    const [isTableVisible, setIsTableVisible] = useState(true);

    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.user_id

    const validationEditSchema = Yup.object().shape({
        course_name_upd: Yup.string().required('Course Name is required'),
        course_type_upd: Yup.string().required('Course Type is required'),
    });

    const getFileName = (url: any) => {
        const parts = url.split('/');
        return parts[parts.length - 1];
    };

    const formatFileSize = (bytes: number) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleFileDownload = (fileUrl: string, fileName: string) => {
        // Create an anchor element
        const link = document.createElement('a');
        // Set the href and download attributes
        link.href = fileUrl;
        link.download = fileName;
        // Programmatically trigger the click event
        link.click();
    };

    const CourseDelete = (material_del_id: string) => {
        const handleDeleteEvent = async () => {
            try {
                const response = await COURSE_DOCUMENTS_DELETE_DATA(material_del_id);
                const filterdata = filesData.filter(
                    (item: any) => item.course_material_id !== material_del_id
                );
                setFilesData(filterdata);
            } catch (error) {
                console.error("Error deleting material:", error);
            }
        };
        handleDeleteEvent();
    };

    const handleDeleteConfirmation = (material_del_id: string) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this material..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                CourseDelete(material_del_id);
                Swal.fire(
                    'Deleted!',
                    'Your course has been deleted.',
                    'success'
                )
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handleUpdateSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('course_id_upd', values.course_id_upd);
            formData.append('course_name_upd', values.course_name_upd);
            formData.append('course_type_upd', values.course_type_upd);
            formData.append('course_fee_upd', values.course_fee_upd);
            formData.append('course_remarks_upd', values.course_remarks_upd);
            formData.append('course_modifed_by', user_id);
            const response = await COURSE_PUT_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Course Update successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                fetchCourseData("0");
                setLoading(false);
                setShowEditModal(false);
                actions.resetForm();
            } else {
                toast.error("Failed to update course", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    const handleDocumentSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true);
        try {
            if (images.length === 0) { // Check if no images are selected
                toast.error("Please select at least one image for upload", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    },
                    duration: 5000
                });
                setLoading(false);
                return; // Exit function if no images are selected
            }
            const formData = new FormData();
            formData.append('material_course_id', values.course_id_upd);
            formData.append('material_upload_by', user_id);
            images.forEach((image: any) => {
                formData.append('material_new[]', image.file);
            });
            const response = await COURSE_DOCUMENTS_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Course Update successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setImages([])
                setShowEditModal(false);
                actions.resetForm();
            } else {
                toast.error("Failed to update course", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Edit Course Details:</h5>
                    <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#course_details"
                            >
                                Course Details
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#course_documents"
                            >
                                Course Documnents
                            </a>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="course_details" role="tabpanel">
                            {loadingEdit ? (
                                <Loader />
                            ) : (
                                <>
                                    <Formik validationSchema={validationEditSchema} initialValues={initialEditValues} onSubmit={handleUpdateSubmit}>
                                        {() => (
                                            <Form>
                                                <>
                                                    <div className="form-group">
                                                        <input type="hidden" name="course_id_upd" className="form-control" value="" />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="course_name_upd" className="form-label mb-3 required">Course Name</label>
                                                        <Field name="course_name_upd" type="text" className="form-control form-control-sm" />
                                                        <ErrorMessage name="course_name_upd" component="div" className="text-danger" />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="form-label mb-3 required" htmlFor="course_type_upd">Course Type</label>
                                                        <Field as="select" name="course_type_upd" aria-label="Select example" className="form-select form-select-sm">
                                                            <option value="">Select a type</option>
                                                            <option value="Online">Online</option>
                                                            <option value="Offline">Offline</option>
                                                        </Field>
                                                        <ErrorMessage name="course_type_upd" component="div" className="text-danger" />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="course_fee_upd" className="form-label mb-3">Course Fee</label>
                                                        <Field name="course_fee_upd" type="text" className="form-control form-control-sm" />
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label htmlFor="course_remarks_upd" className="form-label mb-3">Course Remarks</label>
                                                        <Field as="textarea" name="course_remarks_upd" type="text" className="form-control form-control-sm" />
                                                    </div>

                                                    <div className='d-flex justify-content-end'>
                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                            {!loading && 'Submit'}
                                                            {loading && (
                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </div>
                        <div className="tab-pane fade" id="course_documents" role="tabpanel">
                            <div className='float-end mb-5'>
                                <button className="btn-primary btn btn-sm" type="button" onClick={() => setIsTableVisible(!isTableVisible)}>
                                    {isTableVisible ? "Add new" : "See documents"}
                                </button>
                            </div>
                            <br />
                            {isTableVisible ? (
                                filesData.length > 0 ? (
                                    <table className="table table-hover table-sm">
                                        <thead>
                                            <tr className='fw-bold fs-6'>
                                                <th className='ps-6'>File Name</th>
                                                <th className='text-center'> Download</th>
                                                <th className='text-center'>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filesData.map((file: any, index: any) => (
                                                <tr key={index}>
                                                    <td className='ps-6'>
                                                        {getFileName(file.course_material_url)}
                                                    </td>
                                                    <td className='text-center' onClick={() => handleFileDownload(file.course_material_url, getFileName(file.course_material_url))}>
                                                        <i className="bi bi-download btn btn-light-primary btn-sm p-2 fs-1" />
                                                    </td>
                                                    <td className='text-center text-hover-primary' onClick={() => handleDeleteConfirmation(file.course_material_id)}>
                                                        <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : <>"No document found"</>)
                                :
                                <>

                                    {loadingEdit ? (
                                        <Loader />
                                    ) : (
                                        <> 
                                            <Formik initialValues={initialEditValues} onSubmit={handleDocumentSubmit}>
                                                {() => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <input type="hidden" name="course_id_upd" className="form-control" />
                                                        </div>

                                                        <div className='mt-5'>
                                                            <label className="form-label mb-5">Course Material (Upload Documents - Multiple)</label>
                                                            <label className="file-attachment text-primary">
                                                                <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                                                <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                            </label>
                                                        </div>
                                                        {uploadError.length > 0 && (
                                                            <div className="text-danger mt-5">
                                                                {uploadError.map((error: any, index: any) => (
                                                                    <div key={index}>{error}</div>
                                                                ))}
                                                            </div>
                                                        )}

                                                        {images.length > 0 ? (
                                                            <table className="table table-hover table-sm">
                                                                <thead>
                                                                    <tr className='fw-bold fs-6'>
                                                                        <th className='ps-8'>File Name</th>
                                                                        <th className='text-center'> Size</th>
                                                                        <th className='text-center'>Remove</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {images.map((image: any, index: any) => (
                                                                        <tr key={index}>
                                                                            <td className='ps-8'>
                                                                                {image.file.name}
                                                                            </td>
                                                                            <td className='text-center'>
                                                                                {formatFileSize(image.file.size)}
                                                                            </td>
                                                                            <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                                                <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        ) : <></>}

                                                        <div className='d-flex justify-content-end mt-5'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik> 
                                        </>
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseEdit