import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import LeadsForm from './LeadsForm';
import LeadsEdit from './LeadsEdit';
import { LEADS_EDIT_DATA, LEADS_DELETE_DATA, Tags_GET_data, Tags_POST_data, LEADS_ADD_CONTACTS } from '../../modules/auth/core/_requests';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import Pagination from '../Pagination';
import Loader from '../Loader';
import LeadsView from './LeadsView';
import Swal from 'sweetalert2';
import { LeadDatas, UserDetails } from '../Interface';
import { GET_USER_DATA, fetchLeadsData } from '../CRM_GET';
import PaginationNew from '../PaginationNew';
import { useAuth } from '../../modules/auth';
import LeadsImport from './LeadsImport';




const Leads = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth()
  const [userRole] = useState(currentUser?.user_role || '')
  const { leads_status } = useParams<{ leads_status: string }>();
  const [refreshLeads, setRefreshLeads] = useState(false);
  const [leadsModal, setLeadsModal] = useState(false)
  const [importModal, setImportModal] = useState(false);
  const [userData, setUserData] = useState<UserDetails[]>([]);
  const [leadsData, setLeadsData] = useState<LeadDatas[]>([]);
  const [leadsViewDatas, setLeadsViewDatas] = useState<LeadDatas[]>([]);
  const [logViewDatas, setLogViewData] = useState<LeadDatas[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [showEditModal, setShowEditModal] = useState(false)
  const [imageUpload, setImageUpload] = useState<File[]>([]);
  const [leadsOwnerSelected, setLeadsOwnerSelected] = useState<{ value: string; label: string }>();
  const [leadsOwnerName, setLeadsOwnerName] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [showPerPage, setShowPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState(leads_status)
  const [filterSource, setFilterSource] = useState('')
  const [leadsTotalDatas, setLeadsTotalDatas] = useState<LeadDatas[]>([]);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
  const [tagsAdd, setTagsAdd] = useState(false);
  const [newTags, setNewTags] = useState('');
  const [tagsError, setTagsError] = useState('');
  const [showAllTags, setShowAllTags] = useState(false);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [getTags, setGetTags] = useState<{ tag_id: string; tag_name: string, tag_created_on: string }[]>([]);
  const [tagsSelected, setTagsSelected] = useState<Array<{ value: string; label: string }>>([]);
  const [initialEditValues, setInitialEditValues] = useState(
    {
      leads_id_upd: '',
      leads_title_upd: '',
      leads_first_name_upd: '',
      leads_last_name_upd: '',
      leads_phone_number_upd: '',
      leads_email_upd: '',
      leads_current_qualification_upd: '',
      leads_country_interested_upd: '',
      leads_course_interested_upd: '',
      leads_source_upd: '',
      leads_status_upd: '',
      leads_address_street_upd: '',
      leads_address_city_upd: '',
      leads_address_state_upd: '',
      leads_address_country_upd: '',
      leads_description_upd: ''
    }
  )

  const UserDetails: any = localStorage.getItem('kc-auth')
  const userDetailsObject = JSON.parse(UserDetails);
  const Login_user_id = userDetailsObject?.user_id

  const validationEditSchema = Yup.object().shape({
    leads_first_name_upd: Yup.string().required('First Name is required'),
    leads_email_upd: Yup.string().email('Invalid email'),
    leads_phone_number_upd: Yup.string().matches(/^[0-9]{12}$/, 'Phone number must be exactly 12 digits').required('Phone number is required'),
    leads_status_upd: Yup.string().required('Status is required'),
  });

  useEffect(() => {
    GET_USER_DATA("0", setUserData);
  }, []);

  const handleLeadsModel = () => {
    setLeadsModal(true)
  }

  const handleImportLeads = () => {
    setImportModal(true);
  };

  const toggleExpand = (id: any) => {
    setExpandedItemId(prevId => (prevId === id ? null : id));
    setShowAllTags(!showAllTags)
  };

  useEffect(() => {
    fetchLeadsData("0", setLeadsData, setIsLoading);
  }, []);

  const RefreshLeadsData = () => {
    setRefreshLeads(true);
    fetchLeadsData("0", setLeadsData, setIsLoading)
      .then(response => {
        // Handle the returned data here 
        setRefreshLeads(false);
      })
      .catch(error => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  const handleTags = (selected: any) => {
    setTagsSelected(selected);
  };

  const FetchTagsData = async (all_tags: any) => {
    try {
      const response = await Tags_GET_data(all_tags);
      setGetTags(response.data.tag_details);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  useEffect(() => {
    FetchTagsData("0");
  }, []);

  const handleAddTags = async (values: any) => {
    try {
      const newTagsValue = values.tags;
      if (!newTagsValue) {
        setTagsError('Tags name cannot be empty');
        return; // Exit early if the tags is empty
      }
      const isOptionAlreadySelected = tagsSelected.some(option =>
        option.value.toLowerCase() === newTagsValue
      );

      if (!isOptionAlreadySelected) {
        const { data: auth } = await Tags_POST_data(
          newTagsValue,
          Login_user_id,
        );

        if (auth === 'Already Exist') {
          setTagsError('Tags already exists. Please select from the dropdown.');
        } else {
          FetchTagsData("0");
          setTagsSelected(prevTagsSelected => [
            ...prevTagsSelected,
            { value: newTagsValue, label: values.tags }
          ]);
          setNewTags('');
          setTagsError('');
        }
      } else {
        setTagsError('Tags already exists. Please select from the dropdown.');
      }
    } catch (error) {
      console.error('Error adding tags:', error);
    }
  };

  const handleViewLeads = async (leads_id: any) => {
    try {
      setLoadingEdit(true)
      const response = await LEADS_EDIT_DATA(leads_id);
      setLeadsViewDatas(response.data.leads_details[0])
      setLogViewData(response.data.activity_log)
      const leadsOwnerId = response.data.leads_details[0].leads_owner;
      const selectedUser: any = userData.find(item => item.user_id === leadsOwnerId);

      // If user is found, set selectedOption to user_name, otherwise set it to null
      if (selectedUser) {
        setLeadsOwnerName(selectedUser.user_name);
      } else {
        setLeadsOwnerName(null);
      }


    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setLoadingEdit(false);
    }
  }

  const handleEditLeads = async (leads_id: any) => {
    try {
      setLoadingEdit(true)
      setShowEditModal(true)
      const response = await LEADS_EDIT_DATA(leads_id);
      const initialData = response.data.leads_details[0]
      setImageUpload([response.data.leads_details[0].leads_profile]);
      const leadsOwnerResponse = response.data.leads_details[0].leads_owner;
      const leadOwnerValues = leadsOwnerResponse
      // Add null check for leadsOwnerSelected
      let selectedOption: any = null;
      selectedOption = userData.find((item: any) => item.user_id === leadOwnerValues);
      if (selectedOption) {
        setLeadsOwnerSelected({
          value: selectedOption.user_id,
          label: selectedOption.user_name
        });
      }
      const tagResponse = response.data.leads_details[0].leads_tags;
      const tagData = JSON.parse(tagResponse);
      const formattedArray = tagData.map((item: any) => ({ value: item, label: item }));
      setTagsSelected(formattedArray);
      setInitialEditValues({
        leads_id_upd: initialData.leads_id,
        leads_title_upd: initialData.leads_title,
        leads_first_name_upd: initialData.leads_first_name,
        leads_last_name_upd: initialData.leads_last_name,
        leads_phone_number_upd: initialData.leads_phone_number,
        leads_email_upd: initialData.leads_email,
        leads_current_qualification_upd: initialData.leads_current_qualification,
        leads_country_interested_upd: initialData.leads_country_interested,
        leads_course_interested_upd: initialData.leads_course_interested,
        leads_source_upd: initialData.leads_source,
        leads_status_upd: initialData.leads_status,
        leads_address_street_upd: initialData.leads_address_street,
        leads_address_city_upd: initialData.leads_address_city,
        leads_address_state_upd: initialData.leads_address_state,
        leads_address_country_upd: initialData.leads_address_country,
        leads_description_upd: initialData.leads_description,
      })
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setLoadingEdit(false);
    }
  };

  const handleMoveProspects = async (leads_id: any) => {
    try {
        // Create a promise for the operation
        const promise = LEADS_ADD_CONTACTS(leads_id, Login_user_id);

        // Use toast.promise to handle the promise
        toast.promise(
            promise,
            {
                loading: 'Moving in progress...',
                success: <b>Successfully Lead moved to prospects</b>,
                error: <b>Could not save.</b>,
            },
            {
                style: {
                    background: '#3DC13C',
                    color: 'white',
                },
                duration: 1500,
            }
        );

         
        setTimeout(() => {
            navigate('/contacts');
        }, 1500);
    } catch (error) {
        console.error('Error fetching leads:', error);
        toast.error("Failed to move", {
            style: {
                background: '#F13637',
                color: 'white',
            },
            duration: 5000
        });
    }
};

  const handleLeadsDelete = (lead_trash_id: string) => {
    const handleDeleteEvent = async () => {
      try {
        const response = await LEADS_DELETE_DATA(lead_trash_id);
        const filterdata = leadsData.filter(
          (item) => item.leads_id !== lead_trash_id
        );
        setLeadsData(filterdata);
      } catch (error) {
        console.error("Error deleting leads:", error);
      }
    };
    handleDeleteEvent();
  };

  const handleDeleteConfirmation = (lead_trash_id: string) => {
    Swal.fire({
      title: 'Are you sure!',
      text: "Delete this leads..?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
        cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Call your delete function here
        handleLeadsDelete(lead_trash_id);
        Swal.fire(
          'Deleted!',
          'Your leads has been deleted.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Canceling will not save any changes :)',
          'error'
        )
      }
    });
  };

  useEffect(() => {
    const filtered = leadsData?.filter((app: any) => {
      const appData = `${app.leads_id} ${app.leads_first_name} ${app.leads_last_name} ${app.leads_phone_number} ${app.leads_email} ${app.leads_owner}`.toLowerCase();
      return appData.includes(searchQuery.toLowerCase());
    });
    setLeadsTotalDatas(filtered);
  }, [searchQuery, leadsData]);

  const sortByColumn = (column: string) => {
    const sortedData = [...leadsData];

    if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
      sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
      setSortOrder('desc');
    } else {
      sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
      setSortOrder('asc');
    }
    setSortColumn(column);
    setLeadsData(sortedData);
  };

  useEffect(() => {
    const filteredOrders = leadsData.filter((item) => {
      if (!filterStatus && !filterSource) {
        return true;
      }
      return (
        (!filterStatus || item.leads_status === filterStatus) &&
        (!filterSource || item.leads_source === filterSource)
      );
    });
    setLeadsTotalDatas(filteredOrders);
  }, [filterStatus, filterSource, leadsData]);

  const handleLeadStatusFilter = (e: any) => {
    setFilterStatus(e.target.value);
  };

  const handleLeadSourceFilter = (e: any) => {
    setFilterSource(e.target.value);
  };

  const handleItemsPerPageChange = (perPage: number) => {
    setShowPerPage(perPage);
    setCurrentPage(1);
  };

  const itemsPerPage = 10;
  const totalItems = leadsData.length;
  const filterDataTotal = leadsTotalDatas.length
  const totalPages = Math.ceil(totalItems / showPerPage);
  const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
  const startIndex = (currentPage - 1) * showPerPage;
  const endIndex = startIndex + showPerPage;
  const leadsDetails = leadsTotalDatas.slice(startIndex, endIndex);
  return (
    <>
      <div className='card bg_none'>
        <div className='card-header p-0 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Leads</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {leadsTotalDatas.length} Leads</span>
          </h3>
          <div className='card-toolbar'>
            <button className="btn btn-primary refresh_icon me-5" onClick={RefreshLeadsData}>{!refreshLeads ? (
              <i className="bi bi-arrow-clockwise fs-2"></i>
            ) : (
              <i className="spinner-border spinner-border-sm fs-2"></i>
            )} </button>
            <button className="btn btn-primary refresh_icon me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              <i className="las la-filter fs-2" />
            </button>
            <div className='me-5'>
              <button className='btn btn-info' onClick={handleImportLeads}>
                <KTIcon iconName='file-down' className='fs-2' />
                Import
              </button>
            </div>
            <div>
              <button className='btn btn-primary refresh_icon' onClick={handleLeadsModel}>
                <KTIcon iconName='plus-circle' className='fs-2' />
                Create Lead
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="collapse" id="collapseExample">
        <div className='card bg_none'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
            </h3>
            <div className='card-toolbar'>
              <div className='me-5'>
                <select className='form-select' onChange={handleLeadSourceFilter}
                  value={filterSource}>
                  <option value="">Leads Source</option>
                  <option value="Advertisement">Advertisement</option>
                  <option value="Employee Referral">Employee Referral</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Google">Google</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Email">Email</option>
                  <option value="Direct Walking">Direct Walking</option>
                  <option value="Cold Call">Cold Call</option>
                  <option value="External Referral">External Referral</option>
                  <option value="Partner">Partner</option>
                  <option value="Public Relations">Public Relations</option>
                  <option value="Web Research">Web Research</option>
                </select>
              </div>
              <div className='me-5'>
                <select
                  className='form-select'
                  onChange={handleLeadStatusFilter}
                  value={filterStatus}
                >
                  <option value="">Lead Status</option>
                  <option value="1">Interested</option>
                  <option value="2">Not interested</option>
                  <option value="3">Beyond Intake</option>
                  <option value="4">Lost to competitor</option>
                  <option value="5">Financially not sound</option>
                </select>
              </div>
              <div className="d-flex align-items-center position-relative">
                <div className="input-group">
                  <input type="search" placeholder='Search Name & Phone...'
                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control w-50" />
                  <button type="button" className="btn btn-primary">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (leadsDetails.length > 0 ? (
        <div className={`mt-5 table-responsive ${refreshLeads ? 'd opacity-50' : ''}`} >
          <table className='table align-middle gs-0 gy-4'>
            <thead className='table_bg_color'>
              <tr className='fw-bold fs-6'>
                <th className={`ps-5 ${sortColumn === 'leads_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_id')}>
                  S.No
                </th>
                <th className={`${sortColumn === 'leads_owner' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_owner' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_owner')}>
                  Owner
                </th>
                <th className={`${sortColumn === 'leads_first_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_first_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_first_name')}>
                  Name
                </th>
                <th className={`${sortColumn === 'leads_phone_number' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_phone_number' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_phone_number')}>
                  Phone
                </th>
                <th className={`${sortColumn === 'leads_address_city' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_address_city' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_address_city')}>
                  Location
                </th>
                <th>
                  Qualification
                </th>
                <th>
                  Course
                </th>
                <th className={`${sortColumn === 'leads_tags' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_tags' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_tags')}>
                  Tags
                </th>
                <th className={`${sortColumn === 'leads_status' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_status' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_status')}>
                  Status
                </th>
                <th className={`min-w-100px ${sortColumn === 'leads_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_created_on')}>
                  Created by
                </th>
                <th>Remarks</th>
                <th className='pe-5'>Actions</th>
              </tr>
            </thead>
            <tbody className='bg-light'>
              {leadsDetails.map((leads, index) => (
                <tr key={index} className='border-bottom  border-gray-300'>
                  <td className='text-dark fw-bold text-hover-primary fs-6 ps-5'>{leads.leads_id}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {leads.leads_owner}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6 w-200px'>
                    <div className="symbol symbol-circle symbol-35px">
                      <img src={leads.leads_profile ? leads.leads_profile : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                    </div>
                    {leads.leads_first_name} {leads.leads_last_name}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_phone_number ? leads.leads_phone_number : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_address_city ? leads.leads_address_city : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_current_qualification ? leads.leads_current_qualification : "-"}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {leads.leads_course_interested ? leads.leads_course_interested : "-"} <br />
                    <small> {leads.leads_country_interested ? leads.leads_country_interested : "-"} </small>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {Array.isArray(leads.leads_tags) ? (
                      <>
                        {leads.leads_tags.length > 0 && (
                          <span className="badge badge-success me-2 p-1">{leads.leads_tags[0]}</span>
                        )}
                        <div className="dropdown d-inline">
                          <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            {leads.leads_tags.length > 1 && (
                              <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(leads.leads_id)}>
                                {expandedItemId === leads.leads_id ? "Hide" : "+"}
                                {expandedItemId === leads.leads_id ? "" : leads.leads_tags.length - 1}
                              </span>
                            )}
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">

                            {showAllTags &&
                              leads.leads_tags.slice(1).map(tag => (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"

                                  >
                                    <span key={tag}>{tag}</span>
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {leads.leads_status === '1' ? (
                      <span className='badge bg_badge_color3 p-2 text-white'>Interested</span>
                    ) : leads.leads_status === '2' ? (
                      <span className='badge bg-danger p-2 text-white'>Not interested</span>
                    ) : leads.leads_status === '3' ? (
                      <span className='badge bg-info p-2 text-white'>Beyond Intake</span>
                    ) : leads.leads_status === '4' ? (
                      <span className='badge bg_badge_color2 p-2 text-white'>Lost to competitor</span>
                    ) : leads.leads_status === '5' ? (
                      <span className='badge bg_badge_color1 text-white'>Financially not sound</span>
                    ) : (
                      <>-</>
                    )}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_created_by}
                    <span className="fw-semibold d-block fs-7">{leads.leads_created_on}</span>
                  </td>
                  <td className="text-dark fw-bold text-hover-primary fs-6">
                    {leads.leads_last_call_attend_by ?
                      <>
                        <small>Last Call:</small><small> {leads.leads_last_call_attend_by ? leads.leads_last_call_attend_by : "-"}</small>
                        <span className="fw-semibold d-block fs-7">{leads.leads_last_call_on ? leads.leads_last_call_on : "-"}</span>
                      </>
                      : "-"}
                  </td>
                  <td>
                    <div className="dropdown">
                      <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-three-dots fs-5"></i>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleEditLeads(leads.leads_id)}
                          >
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleMoveProspects(leads.leads_id)}
                          >
                            Move to prospects
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                            onClick={() => handleViewLeads(leads.leads_id)}
                          >
                            Preview
                          </button>
                        </li>
                        {userRole != "3" && (
                          <>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleDeleteConfirmation(leads.leads_id)}
                              >
                                Delete
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filterStatus || filterSource || searchQuery ? (
            <PaginationNew
              currentPage={currentPage}
              totalPages={filterTotalPage}
              itemsPerPage={itemsPerPage}
              handlePageChange={setCurrentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          ) : (
            <PaginationNew
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={showPerPage}
              handlePageChange={setCurrentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      ) :
        <div className="d-flex justify-content-center p-10">
          <div>
            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} alt='image' className='w-350px' />
            <h3 className="text-center">No leads found</h3>
          </div>
        </div>
      )}

      <div className={`modal${leadsModal ? ' show' : ''}`} role="dialog" style={{ display: leadsModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
          <LeadsForm setLeadsModal={setLeadsModal} fetchLeadsData={fetchLeadsData} setLeadsData={setLeadsData} setIsLoading={setIsLoading}
            tagsAdd={tagsAdd} getTags={getTags} setTagsAdd={setTagsAdd} newTags={newTags} setNewTags={setNewTags}
            tagsSelected={tagsSelected} setTagsSelected={setTagsSelected} handleAddTags={handleAddTags} handleTags={handleTags} tagsError={tagsError}
          />
        </div>
      </div>

      {showEditModal && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
            <LeadsEdit setShowEditModal={setShowEditModal} loadingEdit={loadingEdit} imageUpload={imageUpload}
              initialEditValues={initialEditValues} setImageUpload={setImageUpload} fetchLeadsData={fetchLeadsData} setLeadsData={setLeadsData} setIsLoading={setIsLoading}
              validationEditSchema={validationEditSchema} leadsOwnerSelected={leadsOwnerSelected} setLeadsOwnerSelected={setLeadsOwnerSelected}
              tagsAdd={tagsAdd} getTags={getTags}
              setTagsAdd={setTagsAdd} newTags={newTags} setNewTags={setNewTags} tagsSelected={tagsSelected} handleAddTags={handleAddTags}
              handleTags={handleTags} tagsError={tagsError} setTagsError={setTagsError}
            />
          </div>
        </div >
      )}

      <div className="offcanvas offcanvas-end" style={{ width: '70%', background: "#F0F0F0" }} tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <LeadsView leadsViewDatas={leadsViewDatas} logViewDatas={logViewDatas} leadsOwnerName={leadsOwnerName} loadingEdit={loadingEdit} 
        expandedItemId={expandedItemId} showAllTags={showAllTags} toggleExpand={toggleExpand}
        />
      </div>

      <div className={`modal${importModal ? ' show' : ''}`} role="dialog" style={{ display: importModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <LeadsImport setImportModal={setImportModal} Login_user_id={Login_user_id} fetchLeadsData={fetchLeadsData} setLeadsData={setLeadsData} setIsLoading={setIsLoading} />
        </div>
      </div>
    </>
  )
}

export default Leads