import React, { useState } from 'react';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage, FormikValues } from 'formik';
import * as Yup from 'yup';
import { ADMISSION_PUT_DATA } from '../auth/core/_requests';
import toast from 'react-hot-toast';
import Loader from '../../pages/Loader';
import AllUser from '../../pages/AllUser';

const AdmissionEdit = ({ setShowEditModal, selectedValue, setSelectedValue, courseOptions, courseData, selectedOption,
    handleSelectChange, leadsOptions, contactOptions, CustomOption, taskAssignby, leadsData, customers, initialEditValues, loadingEdit,
    FetchAdmissionList, setTaskAssignby, leadsOwnerSelected, handleTaskAssign, setLeadsOwnerSelected
}: any) => {
    const [loading, setLoading] = useState(false)
    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const userId = userDetailsObject?.user_id

    const validationSchema = Yup.object().shape({
        admission_country_interested_upd: Yup.string().required('Country is required'),
        admission_agent_upd: Yup.string().required('Agent is required'),
        admission_course_intereseted_upd: Yup.string().required('Course is required'),
        admission_student_email_upd: Yup.string().email('Invalid email'),
        admission_student_mobile_upd: Yup.string().matches(/^[0-9]{12}$/, 'Phone number must be exactly 12 digits'),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true);
        try {
            if (!taskAssignby?.value) {
                toast.error("Please select Name", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    },
                    duration: 5000
                });
                setLoading(false);
                return;
            }
            const SourceId = taskAssignby?.value
            const AdmissionName = taskAssignby?.displayLabel
            const LeadOwner = leadsOwnerSelected?.value;
            const response = await ADMISSION_PUT_DATA(
                values.admission_id_upd,
                LeadOwner,
                selectedOption,
                SourceId,
                AdmissionName ? AdmissionName : taskAssignby?.label,
                values.admission_student_mobile_upd,
                values.admission_student_email_upd,
                values.admission_processing_team_upd,
                values.admission_course_intereseted_upd, 
                values.admission_agent_upd,
                values.admission_country_interested_upd,
                values.admission_country_name_upd ,
                values.admission_fee_upd,
                values.admission_exam_status_upd,
                values.admission_status_upd,
                values.admission_for_course_upd,
                values.admission_course_fee_upd,
                values.admission_remarks_upd,
                userId
            )
            if (response.data.message === 'success') {
                toast.success("Admission Updated Successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setTaskAssignby(null as any)
                setLeadsOwnerSelected(null as any)
                actions.resetForm();
                setShowEditModal(false);
                FetchAdmissionList("0", userId);
            } else {
                toast.error("Failed to update admission", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Admission Edit Form :</h4>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowEditModal(false)}></button>
            </div>
            {loadingEdit ? (
                <Loader />
            ) : (
                <Formik initialValues={initialEditValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {(formikProps) => (
                        <Form>
                            <div className="modal-body form_content">
                                <div className="form-group">
                                    <input type="hidden" name="admission_id_upd" className="form-control" />
                                </div>

                                <div className="form-group mb-3">
                                    <label className='form-label mb-3 required'>Lead From</label>
                                    <div className='task_assignee_dropdown'>
                                        <Select
                                            options={AllUser()}
                                            value={leadsOwnerSelected}
                                            placeholder="Search or select..."
                                            isSearchable
                                            menuPosition="fixed"
                                            onChange={handleTaskAssign}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="admission_student_name" className='form-label mb-3 required'>Name</label>
                                    <div className="input-group mb-3">
                                        <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{selectedOption}</button>
                                        <ul className="dropdown-menu">
                                            <li><button className="dropdown-item" onClick={() => handleSelectChange('Lead')}>Lead</button></li>
                                            <li><button className="dropdown-item" onClick={() => handleSelectChange('Contact')}>Contact</button></li>
                                        </ul>
                                        <Select
                                            id="dropdown"
                                            options={selectedOption === 'Lead' ? leadsOptions : contactOptions}
                                            value={taskAssignby}
                                            placeholder="Search or select..."
                                            isSearchable
                                            components={{ Option: CustomOption }}
                                            className={selectedOption === 'Lead' ? 'task_leads_dropdown' : 'task_contacts_dropdown'}
                                            onChange={(selectedOption) => {
                                                setTaskAssignby(selectedOption);
                                                const selectedLeads = leadsData.find((leads: any) => leads.leads_id === selectedOption.value);
                                                const selectedContact = customers.find((cus: any) => cus.contact_id === selectedOption.value);

                                                formikProps.setFieldValue(
                                                    'admission_student_mobile_upd',
                                                    selectedLeads?.leads_phone_number || selectedContact?.contact_mobile || ''
                                                );
                                                formikProps.setFieldValue(
                                                    'admission_student_email_upd',
                                                    selectedLeads?.leads_email || selectedContact?.contact_email || ''
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label mb-3" htmlFor="admission_student_mobile_upd">Phone Number</label>
                                    <Field type="number" name="admission_student_mobile_upd" className="form-control form-control-sm" />
                                    <ErrorMessage name="admission_student_mobile_upd" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label mb-3" htmlFor="admission_student_email_upd" >Email Address</label>
                                    <Field type="text" name="admission_student_email_upd" className="form-control form-control-sm" />
                                    <ErrorMessage name="admission_student_email_upd" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="admission_processing_team_upd" className="form-label mb-3 required">Processing Team</label>
                                    <Field as="select" name="admission_processing_team_upd" aria-label="Select example" className="form-select form-select-sm">
                                        <option value="">Select Team</option>
                                        <option value="KC Pondicherry">KC Pondicherry</option>
                                        <option value="KC Velachery">KC Velachery</option>
                                    </Field>
                                    <ErrorMessage name="admission_processing_team_upd" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="admission_country_interested_upd" className="form-label mb-3 required">Country Interested</label>
                                    <Field as="select" name="admission_country_interested_upd" aria-label="Select example" className="form-select form-select-sm">
                                        <option value="">Select Country</option>
                                        <option value="UK">UK</option>
                                        <option value="US">US</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Australia">Australia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Germany">Germany</option>
                                        <option value="France">France</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Others">Others</option>
                                    </Field>
                                    <ErrorMessage name="admission_country_interested_upd" component="div" className="text-danger" />
                                </div>
                                {formikProps.values.admission_country_interested_upd === "Others" && (
                                    <>
                                        <div className="form-group mb-3">
                                            <label htmlFor="admission_country_name_upd" className="form-label mb-3 required">Enter Country Name</label>
                                            <Field name="admission_country_name_upd" type="text" className="form-control form-control-sm" /> 
                                        </div>
                                    </>
                                )}
                                <div className="form-group mb-3">
                                    <label htmlFor="admission_agent_upd" className="form-label mb-3 required">Agent</label>
                                    <Field as="select" name="admission_agent_upd" aria-label="Select example" className="form-select form-select-sm">
                                        <option value="">Select Agent</option>
                                        <option value="KC Overseas">KC Overseas</option>
                                        <option value="SI UK">SI UK</option>
                                        <option value="Gee Bee">Gee Bee</option>
                                    </Field>
                                    <ErrorMessage name="admission_agent_upd" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="admission_course_intereseted_upd" className="form-label mb-3 required">Program</label>
                                    <Field name="admission_course_intereseted_upd" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="admission_course_intereseted_upd" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="admission_status_upd" className="form-label mb-3 required">Status</label>
                                    <Field as="select" name="admission_status_upd" aria-label="Select example" className="form-select form-select-sm">
                                        <option value="1">Joined</option>
                                        <option value="2">On Going</option>
                                        <option value="3">Cancelled</option>
                                        <option value="4">Completed</option>
                                    </Field>
                                    <ErrorMessage name="admission_status_upd" component="div" className="text-danger" />
                                </div>

                                <div className='form-group mb-3'>
                                    <label className='form-label mb-2'>
                                        Exam Status
                                    </label>
                                    <div className='d-flex align-items-center'>
                                        <div className="form-check form-check-custom form-check-solid ">
                                            <Field
                                                type="radio"
                                                name="admission_exam_status_upd"
                                                value="1"
                                                id="examClear"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="examClear">Exam Cleared</label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-solid ms-5'>
                                            <Field
                                                type="radio"
                                                name="admission_exam_status_upd"
                                                value="2"
                                                id="notClear"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="notClear">Not Cleared </label>
                                        </div>
                                    </div>
                                    <ErrorMessage name='admission_exam_status_upd' component="div" className="text-danger" />
                                </div>

                                {formikProps.values.admission_exam_status_upd == "2" && (
                                    <>
                                        <div className='form-group mb-3 task_assignee_dropdown'>
                                            <label className='form-label mb-3 required'>Course Name</label>

                                            <Select
                                                value={selectedValue}
                                                options={courseOptions}
                                                onChange={(selectedOption) => {
                                                    setSelectedValue(selectedOption);
                                                    const selectedCourseData = courseData.find((course: any) => course.course_id === selectedOption.value);
                                                    // Check if selectedCourseData is not null before setting form values
                                                    if (selectedCourseData) {
                                                        formikProps.setFieldValue('admission_for_course_upd', selectedCourseData.course_type);
                                                        formikProps.setFieldValue('admission_course_fee_upd', selectedCourseData.course_fee);
                                                    } else {
                                                        // If selectedCourseData is null, clear the field values
                                                        formikProps.setFieldValue('admission_for_course_upd', '');
                                                        formikProps.setFieldValue('admission_course_fee_upd', '');
                                                    }
                                                }}
                                            />
                                        </div>

                                        {formikProps.values.admission_course_fee_upd && (
                                            <>
                                                <div className="form-group mb-3">
                                                    <label className="form-label mb-3 required" htmlFor="admission_for_course_upd">Course Type</label>
                                                    <Field name="admission_for_course_upd" type="text" className="form-control form-control-sm" />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="admission_course_fee_upd" className="form-label mb-3">Course Fee</label>
                                                    <Field name="admission_course_fee_upd" type="text" className="form-control form-control-sm" />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                                {formikProps.values.admission_exam_status_upd == "1" && (
                                    <>
                                        <div className="form-group mb-3">
                                            <label htmlFor="admission_remarks_upd" className="form-label mb-3">Description</label>
                                            <Field as="textarea" name="admission_remarks_upd" type="text" className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="admission_fee_upd" className="form-label mb-3">Admission Fee</label>
                                            <Field name="admission_fee_upd" type="text" className="form-control form-control-sm" />
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="modal-footer">
                                <div className='d-flex justify-content-end'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Submit'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default AdmissionEdit;
