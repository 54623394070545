import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { AccountDetails } from '../../../../app/pages/Interface'
import { UserDeatils_Post } from '../../../../app/modules/auth/core/_requests'

const HeaderUserMenu: FC = () => {
  const { logout } = useAuth()
  const [details, setDetails] = useState<AccountDetails>()
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth()
  const [userID] = useState(currentUser?.user_id || '')
  const [userToken] = useState(currentUser?.api_token || '')


  useEffect(() => {
    UserDeatils_Post(userToken, userID)
      .then(response => {
        setDetails(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('API Error:', error);
      });
  }, [userToken]);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {details?.pic != '' ? <img src={details?.pic} className='w-100 rounded-3' alt='User Pic' /> : <div className="symbol-label fs-3 fw-bold bg-primary text-inverse-primary"> {details?.name.charAt(0)}</div>}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {details?.name}
            </div> 
            <span className='fw-bold text-primary fs-7'>
              {details?.role == "1" ? 
              "Super Admin" : details?.role == "2" ? "Admin" : "Employee"}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/account/overview'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link
          to="/account/reset-password" className='menu-link px-5'>
          Reset Password
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
