import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
import Pagination from '../../pages/Pagination';
import Loader from '../../pages/Loader';
import { fetchLeadsData } from '../../pages/CRM_GET';
import { LeadDatas } from '../../pages/Interface';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import PaginationNew from '../../pages/PaginationNew';

const LeadsReport = () => {
    const [refreshLeads, setRefreshLeads] = useState(false);
    const [leadsData, setLeadsData] = useState<LeadDatas[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPerPage, setShowPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAllTags, setShowAllTags] = useState(false);
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [filterStatus, setFilterStatus] = useState('')
    const [filterSource, setFilterSource] = useState('')
    const [leadsTotalDatas, setLeadsTotalDatas] = useState<LeadDatas[]>([]);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
    const [dateState, setDateState] = useState<any>({
        startDate: '',
        endDate: ''
    });

    const RefreshLeadsData = () => {
        setRefreshLeads(true);
        fetchLeadsData("0", setLeadsData, setIsLoading)
            .then(response => {
                // Handle the returned data here 
                setRefreshLeads(false);
            })
            .catch(error => {
                // Handle errors here
                console.error("Error fetching data:", error);
            });
    };

    const toggleExpand = (id: any) => {
        setExpandedItemId(prevId => (prevId === id ? null : id));
        setShowAllTags(!showAllTags)
    };

    function jsonToExcel(jsonData: any[]) {
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    }

    function downloadExcel(data: any[], filename: string) {
        const excelBuffer = jsonToExcel(data);
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }

    useEffect(() => {
        fetchLeadsData("0", setLeadsData, setIsLoading);
    }, []);

    const handleDownloadReport = async () => {
        try {
            // Create a new array with the modified data
            const modifiedData = leadsData.map(lead => {
                const { leads_status, ...rest } = lead;
                const statusType =
                    lead.leads_status === '1' ? 'Attempted to Contact' :
                        lead.leads_status === '2' ? 'Contact in Future' :
                            lead.leads_status === '3' ? 'Pre-Qualified' :
                                lead.leads_status === '4' ? 'Not Qualified' :
                                    '';
                return { ...rest, leads_status: statusType };
            });
            // Download the modified data as an Excel sheet
            downloadExcel(modifiedData, 'Leads Report.xlsx');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const handleDownloadReports = async () => {
    //     try {
    //         const jsonData = leadsData.map(lead => ({
    //             ...lead,
    //             leads_status: lead.leads_status === '1' ? 'Attempted to Contact' :
    //                 lead.leads_status === '2' ? 'Contact in Future' :
    //                     lead.leads_status === '3' ? 'Pre-Qualified' :
    //                         lead.leads_status === '4' ? 'Not Qualified' : 'Unknown'
    //         }));
    //         downloadExcel(jsonData, 'Leads Report.xlsx');
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const handleItemsPerPageChange = (perPage: number) => {
        setShowPerPage(perPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        const filtered = leadsData?.filter((app: any) => {
            const appData = `${app.leads_id} ${app.leads_first_name} ${app.leads_last_name} ${app.leads_phone_number} ${app.leads_email} ${app.leads_owner}`.toLowerCase();
            return appData.includes(searchQuery.toLowerCase());
        });
        setLeadsTotalDatas(filtered);
    }, [searchQuery, leadsData]);

    const sortByColumn = (column: string) => {
        const sortedData = [...leadsData];

        if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
            sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
            setSortOrder('desc');
        } else {
            sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
            setSortOrder('asc');
        }
        setSortColumn(column);
        setLeadsData(sortedData);
    };

    useEffect(() => {
        const filteredOrders = leadsData.filter((item) => {
            const createdDate = new Date(item.leads_created_on.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1')); // Convert date string to Date object
            return (
                (!filterStatus || item.leads_status === filterStatus) &&
                (!filterSource || item.leads_source === filterSource) &&
                (!dateState.startDate || createdDate >= dateState.startDate) &&
                (!dateState.endDate || createdDate <= dateState.endDate)
            );
        });
        setLeadsTotalDatas(filteredOrders);
    }, [filterStatus, filterSource, leadsData, dateState]);

    const handleFilterExport = async () => {
        try {
            // Create a new array with the modified filtered data
            const modifiedFilteredData = leadsTotalDatas.map(lead => {
                const { leads_status, ...rest } = lead;
                const statusType =
                    lead.leads_status === '1' ? 'Attempted to Contact' :
                        lead.leads_status === '2' ? 'Contact in Future' :
                            lead.leads_status === '3' ? 'Pre-Qualified' :
                                lead.leads_status === '4' ? 'Not Qualified' :
                                    '';
                return { ...rest, leads_status: statusType };
            });
            // Download the modified filtered data as an Excel sheet
            downloadExcel(modifiedFilteredData, 'Leads Report.xlsx');
        } catch (error) {
            console.error('Error exporting filtered data:', error);
        }
    };

    const clearDate = () => {
        setDateState({ date: null });
    };

    const handleLeadStatusFilter = (e: any) => {
        setFilterStatus(e.target.value);
    };

    const handleLeadSourceFilter = (e: any) => {
        setFilterSource(e.target.value);
    };

    const itemsPerPage = 10;
    const totalItems = leadsData.length;
    const filterDataTotal = leadsTotalDatas.length
    const totalPages = Math.ceil(totalItems / showPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
    const startIndex = (currentPage - 1) * showPerPage;
    const endIndex = startIndex + showPerPage;
    const leadsDetails = leadsTotalDatas.slice(startIndex, endIndex);

    return (
        <>
            <div className='card bg_none'>
                <div className='card-header p-0 border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Leads Report</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total {leadsTotalDatas.length} Leads</span>
                    </h3>
                    <div className='card-toolbar'>
                        <button className="btn btn-primary refresh_icon me-5" onClick={RefreshLeadsData}>{!refreshLeads ? (
                            <i className="bi bi-arrow-clockwise fs-2"></i>
                        ) : (
                            <i className="spinner-border spinner-border-sm fs-2"></i>
                        )} </button>
                        <button className="btn btn-primary refresh_icon me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <i className="las la-filter fs-2" />
                        </button>
                        <button type="button" className="btn btn-primary refresh_icon" onClick={handleDownloadReport}>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Export
                        </button>
                    </div>
                </div>
            </div>

            <hr />

            <div className="collapse" id="collapseExample">
                <div className='card bg_none'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                        </h3>
                        <div className='card-toolbar'>
                            <div className='d-flex me-2'>
                                <Flatpickr
                                    value={dateState.date}
                                    onChange={([startDate, endDate]) => {
                                        setDateState({ startDate, endDate });
                                    }}
                                    options={{
                                        mode: "range",
                                    }}
                                    className='form-control form-control-solid'
                                    placeholder='Pick date'
                                />
                                {dateState.startDate && dateState.endDate && (
                                    <button className="btn btn-sm btn-secondary" onClick={clearDate}>
                                        <KTIcon iconName='abstract-11' className='' />
                                    </button>
                                )}
                            </div>
                            <div className='me-2'>
                                <select className='form-select' onChange={handleLeadSourceFilter}
                                    value={filterSource}>
                                    <option value="">Leads Source</option>
                                    <option value="Advertisement">Advertisement</option>
                                    <option value="Employee Referral">Employee Referral</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Google">Google</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Email">Email</option>
                                    <option value="Direct Walking">Direct Walking</option>
                                    <option value="Cold Call">Cold Call</option>
                                    <option value="External Referral">External Referral</option>
                                    <option value="Partner">Partner</option>
                                    <option value="Public Relations">Public Relations</option>
                                    <option value="Web Research">Web Research</option>
                                </select>
                            </div>
                            <div className='me-2'>
                                <select
                                    className='form-select'
                                    onChange={handleLeadStatusFilter}
                                    value={filterStatus}
                                >
                                    <option value="">Lead Status</option>
                                    <option value="1">Attempted to Contact</option>
                                    <option value="2">Contact in Future</option>
                                    <option value="3">Pre-Qualified</option>
                                    <option value="4">Not Qualified</option>
                                    <option value="5">Completed</option>
                                </select>
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleFilterExport}
                                disabled={!filterStatus && !filterSource && !searchQuery && !dateState.startDate && !dateState.endDate}
                            >
                                <KTIcon iconName='file-down' className='fs-2 me-2' />
                                Export Filtered
                            </button>
                            <div className="d-flex align-items-center">
                                <div className="input-group">
                                    <input type="search" placeholder='Search Name & Phone...'
                                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                        className="form-control w-50" />
                                    <button type="button" className="btn btn-primary">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (leadsDetails.length > 0 ? (
                <div className={`mt-5 ${refreshLeads ? 'd opacity-50' : ''}`} >
                    <table className='table align-middle gs-0 gy-4'>
                        <thead className='table_bg_color'>
                            <tr className='fw-bold fs-6'>
                                <th className={`ps-5 ${sortColumn === 'leads_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_id')}>
                                    S.No
                                </th>
                                <th className={`${sortColumn === 'leads_owner' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_owner' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_owner')}>
                                    Lead Owner
                                </th>
                                <th className={`${sortColumn === 'leads_source' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_source' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_source')}>
                                    Lead Source
                                </th>
                                <th className={`${sortColumn === 'leads_first_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_first_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_first_name')}>
                                    Name
                                </th>
                                <th className={`${sortColumn === 'leads_tags' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_tags' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_tags')}>
                                    Tags
                                </th>
                                <th className={`${sortColumn === 'leads_phone_number' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_phone_number' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_phone_number')}>
                                    Phone
                                </th>
                                <th className={`${sortColumn === 'leads_email' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_email' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_email')}>
                                    Email
                                </th>
                                <th className={`${sortColumn === 'leads_status' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_status' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_status')}>
                                    Status
                                </th>
                                <th className={`${sortColumn === 'leads_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'leads_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('leads_created_on')}>
                                    Created by
                                </th>
                            </tr>
                        </thead>
                        <tbody className='bg-light'>
                            {leadsDetails.map((leads, index) => (
                                <tr key={index} className='border-bottom  border-gray-300'>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 ps-5'>{leads.leads_id}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {leads.leads_owner}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_source ? leads.leads_source : "-"}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 w-200px'>
                                        <div className="symbol symbol-circle symbol-35px">
                                            <img src={leads.leads_profile ? leads.leads_profile : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                                        </div>
                                        {leads.leads_first_name} {leads.leads_last_name}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {Array.isArray(leads.leads_tags) ? (
                                            <>
                                                {leads.leads_tags.length > 0 && (
                                                    <span className="badge badge-success me-2 p-1">{leads.leads_tags[0]}</span>
                                                )}
                                                <div className="dropdown d-inline">
                                                    <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {leads.leads_tags.length > 1 && (
                                                            <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(leads.leads_id)}>
                                                                {expandedItemId === leads.leads_id ? "Hide" : "+"}
                                                                {expandedItemId === leads.leads_id ? "" : leads.leads_tags.length - 1}
                                                            </span>
                                                        )}
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">

                                                        {showAllTags &&
                                                            leads.leads_tags.slice(1).map(tag => (
                                                                <li>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"

                                                                    >
                                                                        <span key={tag}>{tag}</span>
                                                                    </button>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_phone_number ? leads.leads_phone_number : "-"}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_email ? leads.leads_email : "-"}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {leads.leads_status === '1' ? (
                                            <span className='badge bg_badge_color1 p-2 text-white'>Attempted to Contact</span>
                                        ) : leads.leads_status === '2' ? (
                                            <span className='badge bg_badge_color2 p-2 text-white'>Contact in Future</span>
                                        ) : leads.leads_status === '3' ? (
                                            <span className='badge bg_badge_color3 p-2 text-white'>Pre-Qualified</span>
                                        ) : leads.leads_status === '4' ? (
                                            <span className='badge bg-danger text-white'>Not Qualified</span>
                                        ) : leads.leads_status === '5' ? (
                                            <span className='badge bg-success text-white'>Completed</span>
                                        ) : (
                                            <span className='badge badge-warning text-white'>None</span>
                                        )}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{leads.leads_created_by}
                                        <span className="fw-semibold d-block fs-7">{leads.leads_created_on}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {filterStatus || filterSource || searchQuery ? (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={filterTotalPage}
                            itemsPerPage={itemsPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    ) : (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={totalPages}
                            itemsPerPage={showPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    )}
                </div>
            ) :
                <div className="d-flex justify-content-center p-10">
                    <div>
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} alt='image' className='w-350px' />
                        <h3 className="text-center">No leads found</h3>
                    </div>
                </div>
            )}
        </>
    )
}

export default LeadsReport