import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import toast from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import { handleImageDrop } from '../../pages/CompressImage';
import { USER_POST_DATA } from '../auth/core/_requests';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';


const UserForm = ({ setUserModal, fetchUserData }: any) => {
    const [loading, setLoading] = useState(false)
    const [imageModal, setImageModal] = useState(false)
    const [imageUpload, setImageUpload] = useState<File[]>([]);


    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const userId = userDetailsObject?.user_id ? userDetailsObject?.user_id : "";

    const handleImageModal = () => {
        if (imageUpload.length > 0) {
            setImageModal(false)
        } else {
            setImageModal(true)
        }
    }

    const handleRemoveImage = () => {
        setImageUpload([]);
    }

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const initialValues = {
        user_name: '',
        user_password: '',
        user_phone_number: '',
        user_email_address: '',
        user_dob: '',
        user_department: '',
        user_role: '',
        user_date_of_join: '',
        user_address: '',
        user_remarks: '',
    }

    const validationSchema = Yup.object().shape({
        user_name: Yup.string().required('Name is required'),
        user_password: Yup.string().required('Password is required'),
        user_role: Yup.string().required('Role is required'),
        user_email_address: Yup.string().email('Invalid email'),
        user_phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const LeadsData = new FormData()
            if (imageUpload.length > 0) {
                LeadsData.append("user_profile", imageUpload[0]);
            }
            const response = await USER_POST_DATA(
                LeadsData,
                values.user_name,
                values.user_password,
                values.user_phone_number,
                values.user_email_address,
                values.user_dob,
                values.user_department,
                values.user_role,
                values.user_date_of_join,
                values.user_address,
                values.user_remarks,
                userId
            )
            if (response.data.message === 'success') {
                toast.success("User added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    },
                });
                setUserModal(false)
                setLoading(false)
                fetchUserData("0");
                actions.resetForm()
                setImageUpload([])
            } else {
                toast.error("Failed to add user");
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    }

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Add New User:</h5>
                    <button type="button" className="btn-close" onClick={() => setUserModal(false)} aria-label="Close"></button>
                </div>
                <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                    {() => (
                        <Form>
                            <div className="modal-body">
                                <div className='ms-5 fw-bold'>Profile</div>
                                <div className='symbol symbol-50px symbol-circle ms-5 mt-3 cursor-pointer'>
                                    {imageUpload.length > 0 ? (
                                        (imageUpload.map(file => (
                                            <>
                                                <span onClick={handleRemoveImage} className='cursor-pointer'>
                                                    <KTIcon iconName='cross-square' className='fs-1 text-danger float-end' />
                                                </span>
                                                <div key={file.name} className='symbol symbol-50px symbol-circle ms-5'>
                                                    <img
                                                        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className='w-100'
                                                    />
                                                </div>
                                            </>
                                        )))
                                    ) :
                                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='blank_image' onClick={handleImageModal} />
                                    }
                                </div>
                                {imageUpload.length > 0 ? null : (
                                    <div className='w-25 mt-5 ms-5'>
                                        {imageModal && (
                                            <div {...getRootProps1({ className: "dropzone" })} >
                                                <input className="input-zone" {...getInputProps1()} name='back_image' />
                                                <div className="text-center">
                                                    <p className="dropzone-content">
                                                        Choose JPG or PNG File
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className='ms-5 me-5'>
                                    <div className='row mb-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="user_name" className='form-label mb-3 required'>Name</label>
                                                <Field type="text" name="user_name" className="form-control form-control-sm" />
                                                <ErrorMessage name="user_name" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="user_password" className='form-label mb-3 required'>Password</label>
                                                <Field type="password" name="user_password" className="form-control form-control-sm" />
                                                <ErrorMessage name="user_password" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_phone_number" >Phone Number</label>
                                                <Field type="number" name="user_phone_number" className="form-control form-control-sm" />
                                                <ErrorMessage name="user_phone_number" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_email_address" >Email Address</label>
                                                <Field type="text" name="user_email_address" className="form-control form-control-sm" />
                                                <ErrorMessage name="user_email_address" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_dob">Date of Birth</label>
                                                <Field type="date" name="user_dob" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_department">Department</label>
                                                <Field type="text" name="user_department" className="form-control form-control-sm" />
                                                <ErrorMessage name="user_department" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_role">Role</label>
                                                <Field as="select" name="user_role" aria-label="Select example" className="form-select form-select-sm">
                                                    <option value="">Select a role</option>
                                                    <option value="2">Admin</option>
                                                    <option value="3">Employee</option>
                                                </Field>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_date_of_join" >Date of Joining</label>
                                                <Field type="date" name="user_date_of_join" className="form-control form-control-sm" />
                                                <ErrorMessage name="user_date_of_join" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='ms-5 me-5'>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_address">Address</label>
                                                <Field
                                                    name="user_address"
                                                    className="form-control form-control-sm"
                                                    as="textarea"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="user_remarks" >Remarks</label>
                                                <Field
                                                    name="user_remarks"
                                                    className="form-control form-control-sm"
                                                    as="textarea"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <div className='d-flex justify-content-end'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Submit'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default UserForm