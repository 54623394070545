import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom' 
import Courses from './Courses'
 
 

const CoursesPage: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            > 
                <Route
                    path='all-courses'
                    element={
                        <>
                          <Courses/>
                        </>
                    }
                /> 
            </Route>
        </Routes>
    )
}

export default CoursesPage
